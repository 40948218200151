@for $width from 0 to 200 {
	.w-#{$width}px {
		width: $width + px;
	}
	.h-#{$width}px {
		height: $width + px;
	}
}

$custom-space: (
	0: 0px,
	1: 1px,
	2: 2px,
	3: 3px,
	4: 4px,
	5: 5px,
	6: 6px,
	7: 7px,
	8: 8px,
	9: 9px,
	10: 10px,
	11: 11px,
	12: 12px,
	13: 13px,
	14: 14px,
	15: 15px,
	16: 16px,
	17: 17px,
	18: 18px,
	19: 19px,
	20: 20px,
	21: 21px,
	22: 22px,
	23: 23px,
	24: 24px,
	25: 25px,
	26: 26px,
	27: 27px,
	28: 28px,
	29: 29px,
	30: 30px,
	31: 31px,
	32: 32px,
	33: 33px,
	34: 34px,
	35: 35px,
	36: 36px,
	37: 37px,
	38: 38px,
	39: 39px,
	40: 40px,
	41: 41px,
	42: 42px,
	43: 43px,
	44: 44px,
	45: 45px,
	46: 46px,
	47: 47px,
	48: 48px,
	49: 49px,
	50: 50px,
);

@each $prop, $abbrev in (margin: m, padding: p) {
	@each $size, $length in $custom-space {
		.#{$abbrev}-#{$size}px {
			#{$prop}: $length !important;
		}
		.#{$abbrev}t-#{$size}px {
			#{$prop}-top: $length !important;
		}
		.#{$abbrev}y-#{$size}px {
			#{$prop}-top: $length !important;
			#{$prop}-bottom: $length !important;
		}
		.#{$abbrev}b-#{$size}px {
			#{$prop}-bottom: $length !important;
		}

		.#{$abbrev}e-#{$size}px {
			#{$prop}-right: $length !important;
		}
		.#{$abbrev}x-#{$size}px {
			#{$prop}-right: $length !important;
			#{$prop}-left: $length !important;
		}
		.#{$abbrev}s-#{$size}px {
			#{$prop}-left: $length !important;
		}
	}
}

// font sizes
@for $font from 10 to 41 {
	.fs-#{$font} {
		@include font-size($font + px !important);
	}
}

.center {
	display: flex !important;
	align-items: center !important;
	justify-content: center !important;
}
.side-center {
	display: flex !important;
	align-items: center !important;
}
.between-center {
	display: flex !important;
	align-items: center !important;
	justify-content: space-between !important;
}

.translate-left-middle {
	position: absolute;
	top: 50%;
	left: 0.7%;
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);
	-moz-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	-o-transform: translate(0%, -50%);
}
.translate-right-middle {
	position: absolute;
	top: 50%;
	right: 0%;
	transform: translate(0%, -50%);
	-webkit-transform: translate(0%, -50%);
	-moz-transform: translate(0%, -50%);
	-ms-transform: translate(0%, -50%);
	-o-transform: translate(0%, -50%);
}

@for $space from 1 to 50 {
	.end-#{$space}px {
		right: $space + px !important;
	}

	.start-#{$space}px {
		left: $space + px !important;
	}

	.top-#{$space}px {
		top: $space + px !important;
	}

	.bottom-#{$space}px {
		bottom: $space + px !important;
	}
}


.border-bottom-dotted {
		border-bottom:1px solid $gray-400 !important

}