// Range
//
// Style range inputs the same across browsers. Vendor-specific rules for pseudo
// elements cannot be mixed. As such, there are no shared styles for focus or
// active states on prefixed selectors.

.form-range {
  width: 100%;
  height: add($form-range-thumb-height, $form-range-thumb-focus-box-shadow-width * 2);
  padding: 0; // Need to reset padding
  background-color: transparent;
  appearance: none;

  &:focus {
    outline: 0;

    // Pseudo-elements must be split across multiple rulesets to have an effect.
    // No box-shadow() mixin for focus accessibility.
    &::-webkit-slider-thumb { box-shadow: $form-range-thumb-focus-box-shadow; }
    &::-moz-range-thumb     { box-shadow: $form-range-thumb-focus-box-shadow; }
  }

  &::-moz-focus-outer {
    border: 0;
  }

  &::-webkit-slider-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    margin-top: ($form-range-track-height - $form-range-thumb-height) * .5; // Webkit specific
    @include gradient-bg($form-range-thumb-bg);
    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($form-range-thumb-transition);
    appearance: none;

    &:active {
      @include gradient-bg($form-range-thumb-active-bg);
    }
  }

  &::-webkit-slider-runnable-track {
    width: $form-range-track-width;
    height: $form-range-track-height;
    color: transparent; // Why?
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    border-color: transparent;
    @include border-radius($form-range-track-border-radius);
    @include box-shadow($form-range-track-box-shadow);
  }

  &::-moz-range-thumb {
    width: $form-range-thumb-width;
    height: $form-range-thumb-height;
    @include gradient-bg($form-range-thumb-bg);
    border: $form-range-thumb-border;
    @include border-radius($form-range-thumb-border-radius);
    @include box-shadow($form-range-thumb-box-shadow);
    @include transition($form-range-thumb-transition);
    appearance: none;

    &:active {
      @include gradient-bg($form-range-thumb-active-bg);
    }
  }

  &::-moz-range-track {
    width: $form-range-track-width;
    height: $form-range-track-height;
    color: transparent;
    cursor: $form-range-track-cursor;
    background-color: $form-range-track-bg;
    border-color: transparent; // Firefox specific?
    @include border-radius($form-range-track-border-radius);
    @include box-shadow($form-range-track-box-shadow);
  }

  &:disabled {
    pointer-events: none;

    &::-webkit-slider-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }

    &::-moz-range-thumb {
      background-color: $form-range-thumb-disabled-bg;
    }
  }
}


// custom code
.form-range-fill[type="range"] {
  -webkit-appearance: none;
  margin-right: 15px;
  width: 100%;
  height: 10px;
  background: transparent;
  border: 1px solid $component-active-bg ;
  border-radius: 5px;
  background-image: linear-gradient($component-active-bg, $component-active-bg);
  background-size: 0% 100%;
  background-repeat: no-repeat;
}

.form-range-fill[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  background: $component-active-bg;
  cursor: ew-resize;
  box-shadow: 0 0 2px 0 #555;
  transition: all .3s ease-in-out;
  border: 2px solid $gray-100;
}

.form-range-fill[type=range]::-webkit-slider-runnable-track {
  -webkit-appearance: none;
  box-shadow: none;
  border: none;
  background: transparent;
}
.rangePopever{
  position: absolute;
  inset: auto auto 0px 0px;
  margin: 0px;
  transform:translate3d(284px, 2363px, 0px)
}
.rangePopever-arrow{
  position: absolute;
  left: 0px;
  transform:translate3d(49px, 0px, 0px)
}
.box {
  background-color: $gray-300;
  color: $gray-900;
  padding: 2px 6px;
  position: absolute;
  float: left;
  font-size: 12px;

}

.box.active{
  background-color: $component-active-bg;
  color: $gray-100;
}

.arrow-bottom:after{
    content: " ";
    bottom: 0%;
    position: absolute;
    bottom: -23%;
    left: 50%;
    transform: translate(-50%, 0%);
    -webkit-transform: translate(-50%, 0%);
    -moz-transform: translate(-50%, 0%);
    -ms-transform: translate(-50%, 0%);
    -o-transform: translate(-50%, 0%);

    border-top: 8px solid $gray-300;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: none;

}

.box.arrow-bottom.active:after {
  content: " ";
  bottom: 0%;
  position: absolute;
    bottom: -23%;
  left: 50%;
  transform: translate(-50%, 0%);
  -webkit-transform: translate(-50%, 0%);
  -moz-transform: translate(-50%, 0%);
  -ms-transform: translate(-50%, 0%);
  -o-transform: translate(-50%, 0%);

    border-top: 8px solid $component-active-bg;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: none;

}
