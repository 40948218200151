/*CSS Variables Defined here*/

:root {
    --pink: #ff9e9e;
    --green: #0ba245;
    --gray: #a5a0a0;
    --dark-gray: #524b4b;
    --gray2: #eaeaec;
    --white: #fff;
    --brown: #b5975b;
    --dark-green: #006847;
    --light-brown: #d9bf88;
    --dark-brown: #b39659;
    --light-gray: #e3dbdb;
    --black: #000;
    --light-blue: #ecf0f4;
    --primary: #437ad9;
    --sky-blue: #4460ef;
    --blue: #437ad9;
    --danger: #fe1717;
    --greenesh-blue: #4ecdc4;
    --warning: #ff9f1c;
    --darkPink: #ff6a6a;
    --purple: #7c4ecd;
    --yellow: #fd9f42;
    --medium-gray: #bbb5b5;
    --secondary: #dee2e6;
    --table-striped: #ecf3fd;
    --header-color: #ecf0f4;
}

.table-primary {
    --bs-table-striped-bg: #f6f9fe;
    --bs-table-bg: #ecf3fe;
}

.item-center {
    align-items: center;
}

.language-edit {
    float: right;
    margin-top: -16px;
    margin-right: -14px;
}

.linked {
    width: 50%;
    margin-left: 10px;
}

.coupon {
    display: block;
    padding: 0.36rem 0.75rem;
    font-size: 0.93rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.bg-table-striped {
    background-color: var(--table-striped);
}

/*CSS Variables Defined here*/


/*Define Font Family start here*/

@font-face {
    font-family: "Poppins-Thin";
    src: url("../fonts/Poppins-Thin.ttf");
}

@font-face {
    font-family: "Poppins-Light";
    src: url("../fonts/Poppins-Light.ttf");
}

@font-face {
    font-family: "Poppins-Regular";
    src: url("../fonts/Poppins-Regular.ttf");
}

@font-face {
    font-family: "Poppins-Medium";
    src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
    font-family: "Poppins-Bold";
    src: url("../fonts/Poppins-Bold.ttf");
}

/*Define Font Family ends here*/

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

/*global style*/

html,
body {
    max-width: 100%;
    overflow-x: hidden;
    margin: 0;
    padding: 0;
    background-color: var(--light-blue);
}

.progress-bar,
.progress {
    border-radius: 15px;
}

.border-dashed {
    border-style: dashed;
}

.border-dashed1 {
    border: 1px solid #7f8fc5;
    border-style: dashed;
}

.bg-rejected {
    background-color: var(--gray2);
}

thead {
    -webkit-box-shadow: 0 0 3px var(--medium-gray);
    box-shadow: 0 0 3px var(--medium-gray);
}

a:hover {
    color: var(--primary);
    text-decoration: none;
}

.cards-shadow {
    -webkit-box-shadow: 0 0 1rem var(--medium-gray);
    box-shadow: 0 0 1rem var(--medium-gray);
}

.f-Poppins-Regular {
    font-family: Poppins-Regular;
}

.pf-link {
    background-color: var(--white);
    padding: 0.5rem 0.2rem;
    -webkit-box-shadow: 0 0 3px var(--medium-gray);
    box-shadow: 0 0 3px var(--medium-gray);
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}

.pf-link .text {
    background-color: var(--pink);
    padding: 0.2rem 0.5rem;
    color: var(--white);
    border-radius: 3px;
}

.pf img {
    height: 20px;
}

.p-2 {
    padding: 2rem;
}

.vertical-super {
    vertical-align: super;
}

/*global style*/


/*fonts*/


/*heights starts here*/

.h-50px {
    height: 50px;
}

.h-30px {
    height: 30px;
}

.h-20px {
    height: 20px;
}

/* rahul style */

.h-90px {
    height: 135px;
}

/* rahul style */

.h-40px {
    height: 40px;
}

.h-200 {
    height: 200px;
}

.h-150 {
    height: 150px;
}

.min-height-80 {
    min-height: 80px;
}

.min-height-175 {
    min-height: 175px;
}

.min-height-150 {
    min-height: 150px;
}

.min-height-230 {
    min-height: 230px;
}

.btn-red {
    background-color: var(--danger);
    border-color: var(--danger);
}

.btn-red:hover {
    color: #000;
    background-color: var(--darkPink);
    border-color: var(--darkPink);
}

/*backgrounds starts here*/

.bg-blue {
    background-color: var(--primary) !important;
}

.bg-purple {
    background-color: var(--purple) !important;
}

.bg-greenesh-blue {
    background-color: var(--greenesh-blue);
}

.text-right {
    text-align: right;
}

.bg-dark-pink {
    background-color: var(--darkPink);
}

.bg-warning {
    background-color: var(--warning);
}

.bg-50-size {
    background-size: 50%;
}

.bg-40-size {
    background-size: 40%;
}

.bg-no-repeat {
    background-repeat: no-repeat;
}

.bg-light-blue {
    background-color: var(--light-blue);
}

.bg-transparent {
    background-color: transparent;
}

/*backgrounds ends here*/


/*social icons*/

.social-icons {
    padding: 0;
    list-style: none;
    text-align: center;
}

.social-icons li {
    display: inline-block;
}

.social-icons .fa {
    padding: 0.5rem;
}

.font-bold {
    font-weight: bold;
}

.social-icons a:hover {
    color: var(--brown);
}

.oi-social-icons {
    border-radius: 50%;
    border-color: #fff;
    border-style: solid;
    border-width: 1px;
    text-align: center;
    vertical-align: middle;
}

.icon-sm {
    height: 30px;
    width: 30px;
    line-height: 30px;
}

.icon-small {
    height: 25px;
    width: 25px;
    line-height: 25px;
}

.icon-xs {
    height: 20px;
    width: 20px;
    line-height: 20px;
    font-size: 0.8rem;
}

/*social icons*/


/*font colors*/

.text-white {
    color: var(--white);
}

.text-purple {
    color: var(--purple);
}

.bg-purple {
    background-color: var(--purple);
}

.text-brown {
    color: var(--brown);
}

.text-green {
    color: var(--green);
}

.text-gray {
    color: var(--gray);
}

.text-dark {
    color: var(--primary);
}

.text-sky-blue {
    color: var(--sky-blue);
}

.text-black {
    color: var(--black);
}

.text-dark-gray {
    color: var(--dark-gray);
}

.text-dark-green {
    color: var(--dark-green);
}

.text-red {
    color: var(--danger);
}

/*font colors*/


/*position starts here*/

.position-absolute {
    position: absolute;
}

.position-relative {
    position: relative;
}

.l-0 {
    left: 0;
}

.r-0 {
    right: 0;
}

.t-0 {
    top: 0;
}

.b-0 {
    bottom: 0;
}

.abs-center-y {
    top: 50%;
    transform: translateY(-50%);
}

.abs-center-x-y {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.abs-center-x {
    left: 50%;
    transform: translateX(-50%);
}

/*position starts here*/


/*margins*/

.mb-0 {
    margin-bottom: 0;
}

.mt-20 {
    margin-top: 20%;
}

.m-0 {
    margin: 0;
}

/*margins*/


/*padding*/

.p-0 {
    padding: 0;
}

/*padding*/


/*font sizes*/

.f-2 {
    font-size: 2rem;
}

.f-4 {
    font-size: 4rem;
}

.f-2-5 {
    font-size: 2.5rem;
}

.f-1-5 {
    font-size: 1.5rem;
}

.f-1-2 {
    font-size: 1.2rem;
}

.f-1-4 {
    font-size: 1.4rem;
}

.f-1-1 {
    font-size: 1.1rem;
}

.f-1 {
    font-size: 1rem;
}

.f-0-8 {
    font-size: 0.8rem;
}

.f-0-9 {
    font-size: 0.9rem;
}

.btn-default {
    font-size: 0.9rem;
    color: #585151;
    padding: 0.15rem 0.75rem;
}

/*box shadow*/


/*search bar*/


/*search bar*/

.border-none {
    border: none !important;
}

.placeholder-opacity-0-9::placeholder {
    opacity: 0.9;
}

.opacity-0-4 {
    opacity: 0.4;
}

.opacity-0-3 {
    opacity: 0.3;
}

.boxshadowImg {
    box-shadow: 0px 8px 6px -6px #d3cdcd;
    -mox-box-shadow: 0px 8px 6px -6px #d3cdcd;
}

.opacity-0-9 {
    opacity: 0.9;
}

.opacity-0-6 {
    opacity: 0.6;
}

.opacity-0-7 {
    opacity: 0.7;
}

/*widths*/

.w-100 {
    width: 100%;
}

.w-80 {
    width: 80%;
}

.w-50 {
    width: 50%;
}

.w-90 {
    width: 90%;
}

.logo-1 {
    height: 40px;
}

.w-70 {
    width: 70%;
}

.w-65 {
    width: 65%;
}

.w-75 {
    width: 75%;
}

/* r */

.w-30 {
    width: 150px;
}

/* r */


/*widths*/


/*menu style*/

.overlay,
.sideMenu {
    position: fixed;
    bottom: 0;
}

.overlay {
    top: 0;
    left: -100%;
    right: 100%;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 998;
    transition: all ease 0.2s;
}

.overlay.open {
    left: 0;
    right: 0;
}

.sidebarNavigation {
    margin-bottom: 0;
    z-index: 999;
    justify-content: flex-start;
}

.sidebarNavigation .leftNavbarToggler {
    margin-right: 10px;
    order: -1;
}

.sideMenu {
    left: -100%;
    top: 52px;
    transition: all ease 0.5s;
    overflow: hidden;
    width: 100%;
    z-index: 999;
    max-width: 80%;
    margin-bottom: 0;
    padding: 1rem;
}

.sideMenu.open {
    left: 0;
    display: block;
    overflow-y: auto;
}

.sideMenu ul {
    margin: 0;
    padding: 0 15px;
}

/*menu style*/

.top-icon li {
    margin-right: 10px;
}

/*letter spacing*/

.ls-4 {
    letter-spacing: 4px;
}

.text-shadow-green {
    text-shadow: 1px 1px #95edbe;
}

/*letter spacing*/


/* Form style */

label {
    margin-bottom: 0;
    font-size: 0.9rem;
    font-family: Poppins-Medium;
}

input:focus {
    border: solid 1px var(--primary);
}

input:focus input~label {
    border: solid 1px var(--primary);
}

input {
    -webkit-box-shadow: 0 0 1px var(--medium-gray);
    box-shadow: 0 0 1px var(--medium-gray);
}

.box-shadow {
    box-shadow: none !important;
}

/* Form style */


/*footer*/

.footer {
    border-top: 5px solid var(--dark-brown);
    position: relative;
}

.footer>* {
    z-index: 1;
    position: relative;
}

.footer__bottom {
    background-color: #006747;
    padding: 30px 0 81px 0;
}

.footer__nav ul {
    padding: 0;
}

.footer__nav .menu-item {
    list-style-type: none;
    margin-left: 0;
    margin-bottom: 20px;
    line-height: 1;
}

.footer__nav .menu-item a {
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 3px;
    transition: color 0.2s ease-out;
}

.menu-item a {
    position: relative;
    font-family: "FFMarkStdBold", sans-serif;
    color: #006747;
    display: block;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.input_file {
    border: 1px solid var(--sky-blue);
    min-width: 10rem;
}

/*footer*/


/*buttons*/

.btn-1 {
    background: #b4975b;
    color: #fff;
    border-radius: 10px;
    letter-spacing: 2px;
}

.btn-1:hover {
    background: #d1af69;
    color: #fff;
}

.footer__copyright {
    color: #f9f9f9;
    font-family: "DM Sans", sans-serif;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 3px;
    font-weight: 400;
    text-align: center;
    margin-top: 32px;
    margin-bottom: 20px;
}

.footer__disclaimer p {
    color: #fefefe;
    font-family: "DM Sans", sans-serif;
    font-size: 10px;
    line-height: 15px;
    text-align: center;
    margin: 0;
}

.footer__copyright a {
    letter-spacing: 1px;
}

/*buttons*/

.pl-6,
.px-6 {
    padding-left: 5rem !important;
}

.pr-6,
.px-6 {
    padding-right: 5rem !important;
}

.pb-6,
.py-6 {
    padding-bottom: 5rem !important;
}

.pt-6,
.py-6 {
    padding-top: 5rem !important;
}

.br-5 {
    border-radius: 5px;
}

.br-0 {
    border-radius: 0px;
}

.border-green-line {
    border: solid 2px #053e2c;
}

.border-gray-line {
    border: solid 1px var(--secondary);
}

.z-9 {
    z-index: 9;
}

.border-blue {
    border: solid 2px var(--primary);
}

/* r */

.border-blue1 {
    border: solid 1px var(--primary);
}

/* r */

.border-bottom-blue {
    border-bottom: solid 2px var(--primary);
}

.mr {
    margin-right: 15%;
}

.border-color-blue {
    border-color: var(--primary);
}

.w-200px {
    width: 200px;
}

.mt-55,
.my-55 {
    margin-top: 3rem !important;
}

.mt-6,
.my-6 {
    margin-top: 4rem !important;
}

.mb-6,
.my-6 {
    margin-bottom: 4rem !important;
}

.divider3 {
    width: 83px;
    height: 60px;
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
}

.l--10 {
    left: -10%;
}

.mr-40 {
    margin-right: 40%;
}

.l-2 {
    left: 2%;
}

.l-3 {
    left: 3%;
}

.t--15 {
    top: -15%;
}

.t-50 {
    top: 50%;
}

.r--10 {
    right: -10%;
}

.b--15 {
    bottom: -15%;
}

.vh-80 {
    height: 80vh;
}

.vh-100 {
    height: 100vh;
}

.h-20vh {
    height: 20vh;
}

.h-10vh {
    height: 10vh;
}

.h-30vh {
    height: 30vh;
}

.h-90 {
    height: 90px;
}

.h-25vh {
    height: 25vh;
}

/* height r */

.h-70 {
    height: 70px;
}

.h-172 {
    height: 172px;
}

/* height */

.h-60p {
    height: 60px;
}

.h-90p {
    height: 90px;
}

.h-45p {
    height: 45px;
}

.h-100p {
    height: 100px;
}

.icon-md {
    height: 60px;
    width: 60px;
}

.b-5 {
    bottom: 5%;
}

.cursor {
    cursor: pointer;
}

.vh-40 {
    height: 40vh;
}

.vh-50 {
    height: 50vh;
}

.bg-center {
    background-position: center;
}

.btn-border {
    border: solid 2px var(--blue);
}

.bg-cover {
    background-size: cover;
}

.bg-ecf0f4 {
    background-color: var(--header-color);
}

[aria-expanded="true"]>.icon-up {
    visibility: visible;
}

[aria-expanded="true"]>.icon-down {
    visibility: hidden;
}

.r-2 {
    right: 2%;
}

.f-0-7 {
    font-size: 0.7rem;
}

.f-0-6 {
    font-size: 0.6rem;
}

.w-fit-content {
    width: fit-content;
}

.pagination .page-item.active .page-link,
.page-link:hover {
    color: var(--dark-brown) !important;
    background-color: transparent;
}

.pagination .page-link {
    color: #000;
}

.pagination .page-link.page-nav {
    color: var(--dark-brown);
}

ul#horizontal-list li {
    display: inline;
    margin-right: 1rem;
}

.horizontal-scroll-tabs,
.tabs-list-2 {
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
}

.horizontal-scroll-tabs li {
    white-space: nowrap;
    width: 10%;
}

.tabs-list-2 li {
    width: 22%;
}

.horizontal-scroll-tabs li a:hover,
.tabs-list-2 li a:hover {
    font-weight: bold;
    color: var(--primary);
}

.horizontal-scroll-tabs li.active a,
.tabs-list-2 li.active a {
    font-weight: bold;
    color: var(--primary);
}

.horizontal-scroll-tabs li a,
.tabs-list-2 li a {
    color: var(--gray);
}

.btn-default {
    background-color: #fff;
}

.vertical-middle {
    vertical-align: middle;
}

.badge-default {
    border: solid 1px #726f6f;
    border-radius: 50px;
    color: #726f6f;
    font-weight: 100;
}

@media only screen and (max-width: 768px) {
    .w-100-xs {
        width: 100% !important;
    }

    .position-relative-xs {
        position: relative !important;
    }

    .tansform-none-xs {
        transform: none !important;
    }
}

.navbar-fixed {
    position: fixed;
    z-index: 1000;
}

.home-container {
    padding-top: 65px;
}

.card .amateur-plan-card {
    background-color: #fd6c6e;
    border-radius: 15px;
}

.card .pro-plan-card {
    background-color: #4665eb;
    border-radius: 15px;
}

.card .enterprise-plan-card {
    background-color: #7c53ca;
    border-radius: 15px;
}

.border-solid {
    border-style: solid;
}

.border-none1 {
    border-style: 0px !important;
}

.nav-link {
    color: var(--gray);
}

.contact-link .nav-link {
    font-size: 0.9rem !important;
    padding: 0;
}

.br-top-left-10 {
    border-top-left-radius: 10px;
}

.br-top-right-10 {
    border-top-right-radius: 10px;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

/* rahul branch */

@media screen and (max-width: 768px) {
    .w-xs-100 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .w-x-100 {
        width: 100% !important;
    }
}

@media only screen and (max-width: 600px) {
    .f-xs-5 {
        font-size: 5vw;
    }

    .f-xs-4 {
        font-size: 4vw;
    }

    .f-xs-3 {
        font-size: 3vw;
    }
}

/* rahul branch */

.la-file-import {
    transform: rotate(90deg);
    font-weight: bold;
    font-size: 30px;
}

/* scroll bar */

div.scrollmenu {
    overflow: auto;
    white-space: nowrap;
}

div.scrollmenu a {
    display: inline-block;
    color: #777;
    text-align: center;
    padding: 14px;
    text-decoration: none;
}

div.scrollmenu a:hover {
    color: #3c23ce;
}

.filter-btn .dropdown-menu {
    border-radius: 0;
    border-bottom: solid 1px var(--primary);
    border-left: solid 1px var(--primary);
    border-right: solid 1px var(--primary);
    border-top: none;
    border-bottom-left-radius: 0.2rem;
    border-bottom-right-radius: 0.2rem;
}

.filter-btn .btn-toggle {
    border: solid 1px var(--primary);
}

.filter-btn .btn-toggle:hover,
.filter-btn .dropdown:hover .btn-toggle {
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-bottom: none;
}

.btn-xs {
    font-size: 0.6rem;
}

.placeholder-blue::placeholder {
    color: var(--primary);
}

.new2 {
    border-top: 2px dashed #0400ff;
}

.new3 {
    border: 2px dashed #0400ff;
}

.placeholder-blue::placeholder {
    color: var(--primary);
}

// .content {
//     position: relative;
//     width: 90%;
//     max-width: 400px;
//     margin: auto;
//     overflow: hidden;
// }

.company-template-sidebar .content .content-overlay {
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 0.4s ease-in-out 0s;
    -moz-transition: all 0.4s ease-in-out 0s;
    transition: all 0.4s ease-in-out 0s;
}

.company-template-sidebar .content:hover .content-overlay {
    opacity: 1;
}

.company-template-sidebar .content-image {
    width: 100%;
}

.content:hover .content-overlay {
    opacity: 1;
}

.content-image {
    width: 100%;
}

.content-details {
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 0.3s ease-in-out 0s;
    -moz-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.company-template-sidebar .content:hover .content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
}

.company-template-sidebar .content-details h3 {
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
}

.company-template-sidebar .content-details p {
    color: #fff;
    font-size: 0.8em;
}

.companytemplate ul {
    margin: 0;
    padding: 0;
}

.companytemplate ul.nav li {
    list-style: none;
    width: 300px;
}

.companytemplate ul.nav li a {
    text-decoration: none;
    display: block;
    padding: 5px 5px 5px 15px;
}

.companytemplate ul.nav li ul {
    display: none;
    /*hide input points*/
}

.companytemplate ul.nav li:hover {
    position: relative;
}

.companytemplate ul.nav li:hover>ul {
    display: block;
}

.companytemplate ul.nav li:hover ul {
    position: absolute;
    top: 0;
    left: 220px;
}

/* sidebar company tmeplates */

.show-on-hover:hover .position-absolute {
    visibility: visible !important;
}

.mb--12 {
    margin-bottom: -12px;
}

.fadeIn-bottom {
    top: 80%;
}

.fadeIn-top {
    top: 20%;
}

.fadeIn-left {
    left: 20%;
}

.fadeIn-right {
    left: 80%;
}

/* home background image */


/* .Backgroundimg {
         background-image: url(https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPLI_4X2fsUpYPxnWXZdxFFD30VSchiFmcNQ&usqp=CAU);
         background-size: cover;
    }
     */


/* button hover */


/* button.test-btn{
         width:30px;
         font-size: 10px;
         padding-right: 10px;
         border: 2px solid #FFF;
         color: #FFF;
         display: block;
         background: white;
         transition: .2s ease-in-out 0s;
    }
     button.test-btn:hover{
         width: 100px;
         cursor: pointer;
         transform: 0.1s;
         background-color: transparent;
         color: #000;
    }
     @media only screen and (max-width: 768px) {
         .vertical{
             display: flex;
             width: 100%;
             padding-left: 5px;
             margin-left: 5px;
        }
         .btn-primary {
             border-color: #5a8dee !important;
             background-color: #5a8dee !important;
             color: #fff;
        }
         .shadow-md {
             box-shadow: 0px 5px 10px rgba(90, 116, 148, 0.3);
        }
         .navbar{
             border-radius: 5px;
        }
         .sidebar{
             width: 280px;
             min-height: 100vh;
             box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
             background-color: #fff;
             position: fixed;
             top: 0;
             left: -100%;
             z-index: 1;
             transition: 0.5s;
        }
         .sidebar.active{
             left: 0;
        }
         .sd-header{
             display: flex;
             align-items: center;
             justify-content: space-between;
             padding: 15px;
        }
         .sidebar-overlay{
             position: fixed;
             top: 0;
             left: 0;
             width: 100%;
             height: 100%;
             background-color: rgba(0,0,0,0.4);
             transition: 0.5s;
             opacity: 0;
             visibility: hidden;
        }
         .sidebar-overlay.active{
             opacity: 1;
             visibility: visible;
        }
         .sd-body{
             padding: 15px;
             max-height: calc(100vh - 67px);
             overflow-x: hidden;
        }
         .sd-body ul{
             display: inline-block;
             width: 100%;
             margin-bottom: 0;
             padding: 0;
        }
         .sd-body ul li{
             list-style: none;
             margin-bottom: 8px;
        }
         .sd-body ul li .sd-link{
             display: inline-block;
             width: 100%;
             padding: 10px 16px;
             color: #475f7b;
             background-color: #e5e8ec;
             border-radius: 4px;
             cursor: pointer;
             text-decoration: none;
        }
    }
     */


/* hover effect text */

.ml--10 {
    margin-left: -10px;
}

.ml-1 {
    margin-left: 3rem;
}

.mr--10 {
    margin-right: -10px;
}

.mr-10 {
    margin-right: 10px;
}

.ellipsis-button {
    color: #4665eb;
    font-size: 20px;
    border: none;
}

/* */

.h-550 {
    height: 400px;
}

.modal-backdrop.blur-container {
    backdrop-filter: blur(10px);
    background-color: #012237 70;
    opacity: 1;
}

.custom-heading {
    font-family: Poppins-Bold;
    font-size: 1.3rem;
    font-weight: 700;
}

.poppins-bold {
    font-family: poppins-Bold;
}

.poppins-medium {
    font-family: Poppins-Medium;
    font-size: 1rem;
}

.poppins-semi-bold {
    font-family: Poppins-Medium;
    font-size: 1.1rem;
}

.disabled-link {
    pointer-events: none;
    cursor: default;
}

.ellipsis-button {
    border: none;
    width: 1.5rem;
}

.text-blue {
    color: var(--sky-blue);
}

.h-40px {
    height: 40px;
}

.demo-wrapper {
    border: 1px solid #ced4da;
}

.rdw-editor-main {
    height: 150px !important;
    overflow-x: auto !important;
    border-top: 1px solid #ced4da;
}

.notification {
    width: 300px;
    padding: 0px 3px;
    right: 10px;
    min-height: 100px;
    overflow-x: hidden;
    overflow-y: scroll;
}

@media only screen and (max-width: 768px) {
    .notification {
        right: unset;
    }
}

.notification .noti {
    display: flex;
    align-items: center;
}

.notihead {
    display: block;
    padding: 0.5rem 1rem;
    font-size: 0.96rem;
    border-bottom: 1px solid;
}

.notiread {
    background-color: #ebebeb;
}

.notiunread {
    background-color: #667df1;
    color: #fff !important;
}

.noti {
    margin: 5px 0;
    padding: 10px 5px;
    border-radius: 3px;
}

.allnoticlose {
    display: flex;
    align-items: center;
    justify-content: center;
}

.notiunread .job-title a {
    color: #fff;
}

.left .avatar {
    background-color: red;
    font-size: 23px;
    padding: 5px 0;
    border-radius: 5px;
    color: #fff;
    border: 3px solid #fff;
}

.job-title {
    font-size: 15px;
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 400;
}

.job-desc {
    margin-bottom: 0 !important;
    font-size: 13px;
}

.notification li {
    list-style-type: none;
}

.f-right {
    float: right;
}

.padd_0 {
    padding: 0 !important;
}

.pl-9 {
    padding: 0.9rem 0.5rem;
}

.add-cv {
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-left: 0.6rem;
    font-size: 1.1rem;
    padding-top: 0.2rem;
}

.assign-coordinator {
    display: flex;
    align-items: center;
    font-weight: 600;
    padding-left: 0.6rem;
    font-size: 1.1rem;
    padding-top: 0.3rem;
}

.cursor-pointer {
    cursor: pointer;
}

.quiz-title-container {
    margin-left: 0.5rem;
}

.quiz-status-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    border-radius: 0.2rem;
    margin-bottom: 5px;
    cursor: pointer;
    margin-right: 0.5rem;
    color: var(--white);
}

.quiz-card-flex {
    flex-direction: row;
    flex-wrap: wrap;
}

.attempted-status {
    background: var(--primary);
}

.not-attempted-status {
    background: var(--white);
    border: 1px solid var(--primary);
    color: var(--primary);
}

.skip-status {
    background: var(--danger);
    color:#fff;
}

.campus-dashboard-list {
    height: 40vh;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media only screen and (max-width: 768px) {
    .f-r-10 {
        font-size: 10px !important;
    }

    .f-r-17 {
        font-size: 17px !important;
    }

    .f-r-12 {
        font-size: 12px !important;
    }

    .p-none1 {
        padding: 0px !important;
    }

    .h-r {
        height: 100px !important;
    }

    .f-r-10 {
        font-size: 10px !important;
    }

    .f-r-8 {
        font-size: 8px !important;
    }

    .f-r-17 {
        font-size: 17px !important;
    }

    .f-r-12 {
        font-size: 10px !important;
    }

    .f-r-11 {
        font-size: 11px !important;
    }

    .f-r-14 {
        font-size: 14px !important;
    }

    .f-r-16 {
        font-size: 16px !important;
    }

    .f-r-18 {
        font-size: 18px !important;
    }

    .f-r-20 {
        font-size: 20px !important;
    }

    .m-e {
        margin-right: 15px !important;
    }

    .p-00 {
        padding: 0px !important;
    }

    .mtt-2 {
        margin-top: 25px !important;
    }

    .w-200pxx {
        width: 150px;
    }
}

.scrollbar {
    height: 70vh !important;
    overflow-x: hidden;
    overflow-y: scroll;
}

.boxshadowslider {
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 0 0 5px #000 4d;
}

@media (max-width: 767px) {
    .navbarheight {
        height: auto !important;
    }

    .navbarmenu {
        display: flex;
        width: 100%;
    }
}

@media (min-width: 768px) {
    .navbarheight {
        display: flex !important;
    }
}

select option[disabled] {
    display: none;
}

.slick-prev:before,
.slick-next:before {
    color: black !important;
}

.probable_candidate {
    right: 29.33%;
    position: fixed;
}

@media only screen and (max-width: 768px) {
    .probable_candidate {
        right: unset !important;
        position: relative !important;
    }
}

@media only screen and (min-width: 1800px) {
    .probable_candidate {
        right: 40% !important;
    }
}

.word-break {
    word-break: break-all;
}

.word-break-word {
    word-break: break-word;
}

.badge_parent {
    color: white;
    display: inline-block;
    /* Inline elements with width and height. TL;
        DR they make the icon buttons stack from left-to-right instead of top-to-bottom */
    position: relative;
    /* All 'absolute'ly positioned elements are relative to this one */
    padding: 2px 1px;
    /* Add some padding so it looks nice */
}

/* Make the badge float in the top right corner of the button */

.button__badge {
    background-color: #fa3e3e;
    border-radius: 2px;
    color: white;
    padding: 1px 3px;
    font-size: 10px;
    position: absolute;
    /* Position the badge within the relatively positioned button */
    top: -10px;
    right: 10px;
}

/*=======Pagination=======*/

ul.pagination li.page-item.active .page-link,
ul.pagination li.page-item .page-link:hover {
    background: #4665eb !important;
    border-radius: 3px;
    color: #fff !important;
}

.pagination .page-item {
    margin: 0 3px;
    text-transform: capitalize;
}

.pagination .page-link {
    border: none !important;
}

.cs_arpg {
    display: inline-block;
    width: 30px;
    height: 15px;
    background: url(../icons/next_ar.png) no-repeat center center/16px;
    vertical-align: middle;
    margin: 0 5px;
}

.cs_arl {
    transform: rotate(180deg);
}

.page-link:hover .cs_arpg {
    filter: brightness(0) invert(150%);
}

.pagination .page-item.unable {
    pointer-events: none;
    opacity: 0.5;
    user-select: none;
}

.bg_set1,
.bg_pp {
    background: #7c4ecd;
}

.mr-auto {
    margin-right: auto;
}

.clr_btn {
    display: inline-block;
    padding: 6px 2rem;
    color: #fff;
    background-color: #4665EB;
    border-color: #4665EB;
}

.mr-1 {
    margin-right: 5px;
}

.cs_ratio {
    position: relative;
    display: block;
    overflow: hidden;
}

.cs_ratio::before {
    content: '';
    padding-top: 40%;
    display: block;
    pointer-events: none;
}

.cs_ratio>img {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.ratio_eq:before {
    padding-top: 100%;
}

.ratio_contain>img {
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.cs_block_style {
    border-radius: 12px;
    background: #fff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, .1);
    padding: 1.5rem 2rem;
}

.custom-heading {
    font-family: Poppins-Bold;
    font-size: 1.3rem;
    font-weight: 700;
}

.job_post_title,
.job_post_name {
    font-weight: 400;
    color: #4665eb;
    font-size: 13px;
    margin-bottom: 0;
    margin-bottom: 4px;
}

.c_gray {
    color: #b7b7b7;
}

.job_post_name {
    font-size: 12px;
    color: #b7b7b7;
    font-weight: 500;
    display: block;
}

.job_post_card {
    border-radius: 12px;
    padding: 1rem 1.5rem;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    color: #b7b7b7;
    font-size: 12px;
    margin: 12px;
}

.related_jobs_slider .slick-list {
    margin: 0 -12px;
}

.cs_address mb-0 {
    font-weight: 500;
}

.mr-2 {
    margin-right: 8px;
}

.ml-1 {
    margin-left: 8px;
}

.ml-18 {
    margin-left: 10px;
}

.ml-1 {
    margin-left: 4px;
}

.mr-1 {
    margin-right: 4px;
}

.mb-2 {
    margin-bottom: 8px;
}

.mb-0 {
    margin-bottom: 0;
}

.mb-4 {
    margin-bottom: 1.5rem;
}

.ml-auto {
    margin-left: auto;
}

.cs_tabs .cs_tabs_bd {
    border: 1px solid #f1f1f1;
    background: #f9f9f9;
}

.cs_tabs .tab {
    background: #f9f9f9;
    color: #333;
    text-decoration: none;
    padding: 8px 7px;
    transition: 0.25s;
}

.cs_tabs .tab.active,
.cs_tabs .tab:hover {
    color: #4665EB;
    background: #eaeffd;
}

.flex_g_1 {
    flex-grow: 1;
}

.cs_tab_content {
    font-size: 12px;
}

.cs_column.column_3 {
    column-count: 3;
}

.cs_column a {
    word-break: break-word;
    padding: 3px;
    font-weight: 300;
    display: block;
    color: inherit;
    transition: 0.25;
}

.cs_column a:hover {
    color: #4665EB;
}

/*//=================*/

.cs_label_set .form-check,
.cs_label_set {
    position: relative;
}

.cs_label_set input {
    display: none !important;
}

.cs_label_set label:before {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 0px;
    top: 6px;
    border: 2px solid #fff;
    background: #FFF;
    border-radius: 50%;
    box-shadow: 0 0 0 1px #944195;
}

.cs_label_set input:checked+label:before {
    background: #944195;
}

.cs_checkbox {
    display: none;
}

.cs_checkbox+label:before {
    content: '';
    width: 14px;
    height: 14px;
    position: absolute;
    left: 0px;
    top: 4px;
    border: 2px solid #fff;
    background: #FFF;
    border-radius: 2px;
    box-shadow: 0 0 0 1px #944195;
}

.cs_checkbox:checked+label:after {
    content: '';
    width: 12px;
    height: 5px;
    position: absolute;
    left: 1px;
    top: 7px;
    border: 2px solid #944195;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
}

.cs_inputnumber {
    position: relative;
}

.cs_flag_icon {
    position: absolute;
    left: 1px;
    top: 1px;
    bottom: 1px;
    width: 55px;
    border-right: 1px solid #ced4da;
}

.cs_inputnumber input {
    padding-left: 65px !important;
}

.cs_heading_success {
    position: relative;
    padding-left: 50px;
}

.cs_heading_success:before {
    content: '';
    position: absolute;
    width: 35px;
    height: 35px;
    border: 3px solid #467dd6;
    border-radius: 50%;
    left: 0;
    top: -3px;
}

.cs_heading_success:after {
    content: '';
    width: 20px;
    height: 9px;
    position: absolute;
    left: 8px;
    top: 9px;
    border: 3px solid #467dd6;
    border-width: 0 0 3px 3px;
    transform: rotate(-45deg);
}

.cs_content_box {
    padding-top: 6rem;
    font-size: 20px;
    font-weight: 500;
    color: #212529;
}

.gt_p {
    color: #4765ec;
    text-decoration: none;
    display: inline-block;
}

.gt_p svg {
    width: 17px;
}

.cs_pdtail {
    height: auto;
    min-height: 80px;
}

.cs_pdtail .float-start {
    max-width: 70%;
}

.lang_type_opt>span {
    position: relative;
    display: inline-block;
    padding-left: 2rem;
    margin-right: 1rem;
}

.lang_type_opt>span:before {
    content: '';
    width: 14px;
    height: 6px;
    position: absolute;
    left: 8px;
    top: 7px;
    border: 3px solid #467dd6;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
}

.button__badge {
    padding: 1px 3px;
    height: 20px;
    min-width: 20px;
    border-radius: 50%;
    text-align: center;
    line-height: 20px;
}

.cs_sms .button__badge {
    top: -2px;
    right: 0px;
}

.cs_eqset p {
    margin-bottom: 8px;
    text-transform: capitalize;
}

.cs_exp_date {
    color: #7b7b7b;
    font-size: 95%;
}

.fw-500 {
    font-weight: 500;
}

.fw-600 {
    font-weight: 600;
}

.py_1 {
    padding-top: 4px;
    padding-bottom: 4px;
}

.cs_ratio {
    position: relative;
    display: block;
}

.cs_ratio::before {
    content: '';
    padding-top: 65%;
    display: block;
    pointer-events: none;
}

.ratio_eq::before {
    padding-top: 100%;
}

.prof_upload_label input {
    display: none;
    font-weight: 400;
}

.upload_limit {
    font-size: 11px;
}

.btn_gray,
.btn_gray:focus,
.btn_gray:active {
    color: #212529;
    background: #f0f0f0;
    border-color: #d7d7d7;
}

.btn_gray:hover {
    color: #fff;
    background: #4665EB;
    border-color: #4665EB;
}

/*==========Counter CSS===========*/

.cs_row {
    margin-left: -10px;
    margin-right: -10px;
}

.cs_row>.col,
.cs_row>[class*=col-] {
    padding-left: 10px;
    padding-right: 10px;
}

.bg_set1 {
    background: #7c4ecd;
}

.bg_set2 {
    background: #fd6a6a;
}

.bg_set3,
.bg_sb {
    background: #4ecdc4;
}

.card_wrap {
    border-radius: 5px;
    padding: 1.5rem;
}

.cs_jb_tile {
    font-size: 1rem;
    font-weight: 700;
}

.cs_cout {
    font-size: 2rem;
    font-weight: 300;
}

/*==========Counter CSS===========*/


/*//================Responsive===============*/

@media all and(max-width:1400px) {
    .w-lg-lg {
        width: 283px !important;
    }
}

/*==========Message Screen===========*/

.cs_ratio {
    position: relative;
    display: block;
}

.cs_ratio::before {
    content: '';
    padding-top: 40%;
    display: block;
    pointer-events: none;
}

.cs_ratio>img {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    object-fit: cover;
    transform: translate(-50%, -50%);
}

.ratio_eq:before {
    padding-top: 100%;
}

.ratio_70:before {
    padding-top: 70%;
}

.ratio_contain>img {
    object-fit: contain;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
}

.cs_sidebar_style {
    background: #F8F8F8;
    border-radius: 5px;
    overflow: hidden;
}

.cs_sidebar_header {
    border-bottom: 1px solid #eee;
}

.side_headbar {
    padding: 1.25rem 1rem;
}

.rdnrd_tab a,
.rdnrd_tab {
    font-size: 11px;
    color: #999;
    line-height: 1.1;
}

.rdnrd_tab a:hover {
    color: #4766EB;
}

.fw-500 {
    font-weight: 500 !important;
}

.cs_input_grp input.form-control {
    border-radius: 3px 0 0 3px !important;
    border: 1px solid #e5e5e5 !important;
    box-shadow: none !important;
}

.l_sidebar {
    /*
    position: -webkit-sticky;
    position: sticky;
    top: 100px;
*/
}

.msg_list_wrapper {
    background: #fff;
    // height: calc(100vh - 200px);
    overflow: auto;
}

.msg_list_wrapper .msg_item,
.cs_detail_msg_list .msg_item {
    background: #fff;
    padding: 1rem;
    border-bottom: 1px solid #eee;
    position: relative;
    cursor: pointer;
    transition: 0.12s;
}

.msg_list_wrapper .msg_item:hover {
    background: #f9f9f9;
}

.cs_mg_thumb {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ffbfbf;
}

.msg_list_wrapper .msg_item.active {
    background: #F2F3FA;
}

.msg_list_wrapper .msg_item:before {
    content: '';
    height: 100%;
    width: 5px;
    background: #4766EB;
    background: transparent;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.12s;
}

.msg_list_wrapper .msg_item:hover:before {
    background: #e7e7e7;
}

.msg_list_wrapper .msg_item.active:before {
    background: #4766EB;
}

.cs_msg_subject {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 3px;
}

.msg_item.unread .cs_msg_subject {
    font-weight: 700;
    color: #000;
}

.msg_item.unread {
    font-weight: 700;
    color: #000;
}

.cs_msg_fz {
    font-size: 10px;
    line-height: 1.3;
}

.pl-1 {
    padding-left: 0.25rem !important;
}

.cs_detail_msg_list .cs_msg_subject {
    font-size: 18px;
}

.cs_detail_msg_list .cs_msg_subject {
    font-size: 18px;
}

.cs_date_sent_on {
    font-size: 13px;
}

.fz_90 {
    font-size: 90% !important;
}

.fz_95 {
    font-size: 95% !important;
}

.fz_80 {
    font-size: 80% !important;
}

.fz_85 {
    font-size: 85% !important;
}

.cs_dd_postion {
    top: 0;
    right: 0;
    position: absolute;
}

.cs_dd_postion .dropdown-menu {
    right: 0;
}

.csdot_menu {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: block;
    position: relative;
    transition: .25s;
    text-align: center;
}

.csdot_menu:before {
    content: '...';
    font-size: 28px;
    color: darkgray;
    line-height: 0;
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* font-weight: 700; */
    letter-spacing: -1px;
}

.csdot_menu:after {
    content: none !important;
}

.csdot_menu:hover {
    background: #F2F2F2;
}

.msg_item_header {
    position: relative;
}

.thembtn2,
.thembtn2:focus,
.thembtn2:active {
    display: inline-block;
    padding: 5px 1rem;
    color: #0e1216;
    background: #cccccc;
    outline: none;
    text-decoration: none !important;
    text-align: center;
    border-radius: 3px;
    font-size: 14px !important;
    text-transform: capitalize;
    border: 1px solid #cccccc;
}

.thembtn2:hover {
    background: #000;
    background: #fff;
    color: #0e1216;
    border-color: #a7a7a7;
}

.msg_reply_toggle {
    display: none
}

.cs_detail_msg_list .msg_item {
    margin-bottom: 1rem;
    border-radius: 5px;
}

.bg_transparent {
    background: transparent !important;
}

.style_4::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.style_4::-webkit-scrollbar {
    width: 6px;
    background-color: #F5F5F5;
}

.style_4::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 2px solid #ccc;
}

/*---------*/

.style_5::-webkit-scrollbar {
    width: 6px;
    border-radius: 6px;
    background: #e9e9eb
}

.style_5::-webkit-scrollbar-track {
    border-radius: 6px
}

.style_5::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 6px
}

/*==========Message Screen===========*/


/*==========Upload Image==========*/

.verified_acc {
    position: relative;
    display: inline-block;
    padding-left: 1.6rem;
    font-size: 12px;
}

.verified_acc:before {
    position: absolute;
    width: 18px;
    height: 18px;
    display: block;
    border-radius: 50%;
    border: 2px solid #467dd6;
    left: 0;
    content: '';
}

.verified_acc:after {
    content: '';
    width: 10px;
    height: 4px;
    position: absolute;
    left: 4px;
    top: 7px;
    border: 3px solid #467dd6;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
}

/*==========Upload Image==========*/


/*expend_pack*/

.cnd_card {
    background: #fff;
    border-radius: 0;
}

.expend_pack {
    text-decoration: none;
}

.cnd_card_header {
    border: none;
}

.cnd_card_header:after {
    content: '';
    display: block;
    height: 2px;
    background: lightgray;
    width: 100%;
}

.bg_gb {
    background: var(--greenesh-blue);
}

.bg_red {
    background: #FD6A69;
}

.bg_yl {
    background: #FFC108;
}

.bg_bl {
    background: #467DD6 !important;
}

.list_item {
    list-style-type: none;
    padding-left: 0;
}

.cnd_font_set {
    font-size: 14px;
    font-weight: 500;
}

.list_item li {
    padding: 1rem 0;
    border-bottom: 1px solid #999;
    display: -webkit-flex;
    display: flex;
    justify-content: space-between !important;
}

.list_item li span,
.list_item li strong {
    padding: 4px 0;
    flex: 0 0 48%;
    max-width: 48%;
}

.list_free span+span {
    flex: 0 0 20%;
    max-width: 20%;
}

.themebtn,
.themebtn:focus,
.themebtn:active,
.themebtn2,
.themebtn2:focus,
.themebtn2:active {
    display: inline-block;
    text-decoration: none !important;
    outline: none !important;
    border-radius: 4px;
    background: #4765EC;
    color: #fff;
    padding: 9px 2rem;
    border: 1px solid #4765EC;
    transition: 0.2s;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
}
.themebtnQuizView,
.themebtnQuizView:focus,
.themebtnQuizView:active {
    display: inline-block;
    text-decoration: none !important;
    outline: none !important;
    border-radius: 4px;
    background: #4765EC;
    color: #fff;
    padding: 3px 1px !important;
    border: 1px solid #4765EC;
    transition: 0.2s;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
}

.themeDisablebtn,
.themeDisablebtn:focus,
.themeDisablebtn:active {

    display: inline-block;
    text-decoration: none !important;
    outline: none !important;
    border-radius: 4px;
    padding: 9px 2rem;
    transition: 0.2s;
    text-transform: capitalize;
    text-align: center;
}

.themebtn2 {
    background: #fff;
    border-color: var(--blue);
    color: var(--blue);
}

.themebtn2:hover {
    background: var(--blue);
    border-color: var(--blue);
    color: #fff;
}
.themebtnQuizView {
    background: #fff;
    border-color: var(--blue);
    color: var(--blue);
}


.themebtnQuizView:hover {
    background: var(--blue);
    border-color: var(--blue);
    color: #fff;
}

.text_blue {
    color: #4766EB;
}

.fw_600 {
    font-weight: 600;
}

.themebtn:hover {
    background: #1b3ed5;
    border-color: #1b3ed5;
    color: #fff;
}

.smd_ml_set {
    margin-left: 52%;
}

.cmd_content {
    font-size: 18px;
    font-weight: 500;
    line-height: 2;
}

button.btn-close,
a.btn-close {
    outline: none !important;
    box-shadow: none !important;
}

.cs_label_set .form-check {
    position: relative;
}

.cs_label_set .cs_rel_label {
    margin-left: -1.35rem;
    padding-top: 4px;
}

.number-input {
    border: 1px solid #ddd;
    display: inline-flex;
}

.number-input button,
.number-input .qtyBtn {
    outline: none;
    -webkit-appearance: none;
    background-color: #f1f1f1;
    border: none;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: auto;
    cursor: pointer;
    margin: 0;
    position: relative;
}

.number-input button:after,
.number-input button.plus:before,
.number-input .qtyBtn:after,
.number-input .plus:before {
    content: '';
    width: 10px;
    height: 2px;
    position: absolute;
    top: calc(50% - 1px);
    background: gray;
    left: calc(50% - 5px);
}

.number-input input[type=number] {
    max-width: 70px;
    padding: 8px 5px;
    border: solid #ddd;
    border-width: 0 1px;
    text-align: center;
    margin: 0;
    outline: none !important;
    -moz-appearance: textfield;
    -webkit-appearance: none;
    font-size: 1rem;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.number-input button.plus:before,
.number-input .plus:before {
    transform: rotate(90deg);
}

/*============Notification=============*/

.af_bd_bl:after {
    background: var(--sky-blue);
}

.form-control {
    outline: none !important;
}

.cs_shorting_width {
    max-width: 300px;
    margin-left: auto;
}

.cs_select {
    box-shadow: none !important;
    background: #fff url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAICAYAAADN5B7xAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYxIDY0LjE0MDk0OSwgMjAxMC8xMi8wNy0xMDo1NzowMSAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNS4xIFdpbmRvd3MiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6Q0JFQzc2Mjk3MDI0MTFFNjgwMkNDNjU5MUEwQURGQkEiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6Q0JFQzc2MkE3MDI0MTFFNjgwMkNDNjU5MUEwQURGQkEiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDpDQkVDNzYyNzcwMjQxMUU2ODAyQ0M2NTkxQTBBREZCQSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDpDQkVDNzYyODcwMjQxMUU2ODAyQ0M2NTkxQTBBREZCQSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PtA9FacAAACQSURBVHjaYvTy8nJhYGBoBuLqrVu37mPAAry9vd2AVCNIDRNUsQUQtwIlnLAo9oAqBqsBafgFlYNpckNSDLK9HioHAr8YgU7SATImA7EDVPAEVNE/JNtB4AAQ5zL+//8fZBK6JnQAVgz04xWQkxhADJAAVAKnYhCHCSaKQxOKYhAAOwktVGDOY0BXDAIAAQYA0985LzsgHakAAAAASUVORK5CYII=") no-repeat scroll right 10px center;
}

.cs_select:focus,
.cs_select:hover {
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1) !important;
}

.cs_card {
    padding: 1.5rem;
    border: 0;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: 0.25s;
    background: #fff;
}

.cs_icon_thumb {
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
}

.cs_icon_thumb img {
    max-height: 90%;
    max-width: 90%;
    object-fit: scale-down;
}

.notif_item {
    line-height: 1.4;
    font-size: 15px;
}

.notif_item:hover {
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.2);
}

.pagination .page-link {
    border-radius: 3px;
    box-shadow: none !important;
}

.notification_drop {
    max-width: 275px;
    background: #fff;
    border-radius: 4px 0 4px 4px;
    padding: 1rem;
    position: relative;
}

.notification_drop:before {
    content: '';
    border: 1px solid transparent;
    border-width: 15px 15px 0 15px;
    position: absolute;
    top: -11px;
    right: -10px;
    border-top-color: white;
    transform: rotate(-45deg);
}

.notification_drop .notif_item {
    box-shadow: none !important;
    border-bottom: 1px solid #ccc;
    padding: 1rem 0;
    font-size: 12px;
}

.notification_drop .notif_item p {
    margin-bottom: 5px;
}

.notification_drop .cs_icon_thumb {
    flex: 0 0 50px;
    max-width: 50px;
    height: 50px;
}

.nt_fz {
    font-size: 16px;
}

.notification_drop .cnd_card_header:after {
    height: 1px;
}

.notif_list_dropdown {
    max-height: 300px;
    overflow-y: auto;
    margin-right: -10px;
    padding-right: 10px;
}

.notif_list_dropdown::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.notif_list_dropdown::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.notif_list_dropdown::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 2px solid #ccc;
}

/*=============Recruiter Plans==================*/

.pln_box {
    background: #fff;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 1.1;
}

.pln_box1 {
    flex: 0 0 30%;
    max-width: 30%;
    padding: 0 0 1rem 1rem;
    text-align: left;
    margin-top: 65px;
}

.pln_box ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
}

.pln_box ul li {
    padding: 2px;
    border-bottom: 1px solid #ddd;
    height: 65px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}

.pln_box small {
    font-size: 70%;
    display: block;
}

.pln_box2,
.pln_box3,
.pln_box4 {
    flex: 0 0 23.3%;
    max-width: 23.3%;
    background: #F8F8F8;
}

.pln_box3 {
    //z-index: 1;
    background: #f2f7ff;
}

.pln_box4 {
    background: #fff;
}

.pln_box .themebtn {
    padding: 1rem !important;
    border-radius: 0 !important;
}

.btn_red {
    background: #FB1D26 !important;
    border-color: #FB1D26 !important;
}

.btn_red:hover {
    background: #d5060f !important;
    border-color: #d5060f !important;
}

/*=========Revised cArd=========*/

.p_mb0 p {
    margin-bottom: 0;
}

.row_bd {
    position: relative;
}

.row_bd:after {
    content: '';
    display: block;
    height: 1px;
    left: 15px;
    right: 15px;
    position: absolute;
    bottom: 0;
    background: #ddd;
}

.fw_700 {
    font-weight: 700;
}

.ttl_amount {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
}

.white_btn,
.white_btn:focus,
.white_btn:active {
    background: #fff;
    color: var(--blue);
    border-color: #fff;
}

.white_btn:hover {
    background: var(--blue);
    color: #fff;
    border-color: #fff;
}

/*==========RE0028=========*/

.file-input {
    display: inline-block;
    text-align: left;
    background: #fff;
    padding: 16px;
    /*  width: ;*/
    position: relative;
    border-radius: 3px;
}

.file-input>[type='file'] {
    position: absolute;
    top: 0;
    left: 0;
    width: 110px;
    /*    height: 100%;*/
    opacity: 0;
    z-index: 10;
    cursor: pointer;
}

.file-input .label {
    color: #333;
    white-space: nowrap;
    opacity: .3;
}

.file-input.-chosen .label {
    opacity: 1;
    margin: 0;
}

.file-input .file_label_wrapper .cross_symbol {
    position: absolute;
    right: -18px;
    top: -18px;
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-size: 10px;
    opacity: 1;
    background-position: 7px;
    cursor: pointer;
}

.file_label_wrapper {
    display: block;
    background: #f5f3f3;
    border-radius: 4px;
    padding: 3px;
    margin: 4px 0;
    border: 1px solid #ddd;
    display: none;
    position: relative;
}

.file-input.-chosen .file_label_wrapper {
    display: block;
}

.cs_h5 {
    font-size: 18px;
    font-weight: 600;
}

.per_bar_wrapper {
    border-radius: 10px;
    display: inline-block;
    height: 12px;
    position: relative;
    background: #eee;
    overflow: hidden;
    width: calc(100% - 50px);
}

.per_bar_wrapper .per_bar {
    position: absolute;
    left: 0;
    top: 0;
    background: #467DD6;
    height: 100%;
}

.hr_bl {
    background: #467DD6;
}

.size_2 {
    height: 2px !important;
}

.add_option {
    text-decoration: none;
}

.add_option span {
    vertical-align: bottom
}

.pls_symbol {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 1px solid #467DD6;
    border-radius: 50%;
    position: relative;
}

.pls_symbol:after,
.pls_symbol:before {
    content: '';
    width: 10px;
    height: 1px;
    background: #467DD6;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
}

.pls_symbol:after {
    transform: translate(-50%, -50%) rotate(90deg);
}

.right_symbol {
    position: relative;
    display: inline-block;
    vertical-align: text-bottom;
    width: 25px;
    height: 25px;
    color: #467DD6;
}

.right_symbol:after {
    content: '';
    width: 14px;
    height: 6px;
    position: absolute;
    left: 4px;
    top: 8px;
    border: 3px solid currentColor;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
}

.lbl_mb label {
    margin-bottom: 8px;
}

.form-control:focus,
.form-select:focus {
    box-shadow: none !important;
}

.form-control::placeholder {
    opacity: 1;
    color: #aaa;
}

.form-control::-moz-placeholder {
    opacity: 1;
    color: #aaa;
}

.tag_table thead,
.tag_table thead th {
    background: #467DD6;
    color: #fff;
    border-bottom: none;
}

.tag_table thead th,
.tag_table td {
    padding: 1rem 2rem;
}

.tag_table td {
    border-bottom: 1px solid #ddd;
}

.table>:not(:first-child) {
    border: none;
}

.tag_table th:last-child {
    width: 20%;
}

.tag_table th:nth-child(2) {
    width: 50%;
}

.action_btns a {
    color: #ccc;
    text-decoration: none;
    font-size: 14px;
}

.action_btns a:hover {
    color: #999;
}

.editable_line_field {
    box-shadow: none;
    border: 1px solid #eee;
    border-width: 0 0 1px !important;
    outline: none;
    padding: 0 0 6px;
    color: #999;
    width: 80%;
}

.editable_line_field:focus {
    border-color: #ccc;
}

.cross_symbol {
    display: inline-block;
    width: 25px;
    height: 25px;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/12px auto no-repeat;
    opacity: 0.3;
    vertical-align: text-bottom;
}

#quesTags .modal-body {
    padding: 0;
}

#quesTags .btn-close {
    position: absolute;
    top: -1rem;
    right: 0;
    padding: 6px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 2;
    -webkit-background-size: 10px;
    background-size: 10px;
    opacity: 1;
}

#quesTags .modal-dialog {
    max-width: 850px;
}

.add_new_confirm:not(.active) {
    opacity: 0.3;
    pointer-events: none;
}

/*=============Revised Candidate Plan==================*/

.candidate_plan .pln_box {
    flex: 0 0 33.3%;
    max-width: 33.3%;
}

.cs_form_linline .form-control,
.cs_form_linline .form-select {
    width: auto;
    color: #999;
    border-color: #ccc;
}

.cs_form_linline .cs_search_F {
    width: 40%;
}

/*==================RE0009===============*/
.cs_bn_upload_wrapper {
    max-width: 80%;
    width: 250px;
    margin: 0 auto;
    padding: 1rem !important;
    display: none;
    position: relative;
    z-index: 1;
    box-shadow: 0 7px 15px rgba(0, 0, 0, .2) !important;
}

.cs_bn_upload_wrapper.active {
    display: block;
}

.cs_bn_toggle {
    color: #4765ec;
    border: none;
    outline: none;
    box-shadow: none;
    background: white;
    padding: 1px 2px 3px 5px;
    position: absolute;
    top: 0px;
    right: 0;
    border: 1px solid #ddd;
    border-width: 0 0 1px 1px;
    z-index: 2;
    border-radius: 2px;
}

.cs_bn_toggle:hover {
    border-color: #999;
}

.add_banner_wrapper {
    background: #F4F4F4;
    border: 1px solid #ddd;
    border-radius: 4px;
    height: 300px;
    overflow: hidden;
}

.prf_main_img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.prf_main_img[src=''] {
    display: none;
}

.info_symbol {
    height: 18px;
    line-height: 15px;
    width: 18px;
    border: 1px solid CurrentColor;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    font-size: 14px;
    font-weight: 700;
}

.dotted_border_listing {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.dotted_border_listing li {
    display: flex;
    justify-content: space-between;
    padding: 0 0 10px;
    margin-bottom: 1rem;
    border-bottom: 2px dashed #467DD6;
}

.tbl_set1 table,
.tbl_set1 th,
.tbl_set1 td,
.tbl_set1 thead {
    border: none !important;
    box-shadow: none;
}

.tbl_set1 th,
.tbl_set1 td {
    padding: 1rem 0;
}

.tbl_set1 th:last-child,
.tbl_set1 td:last-child {
    width: 40%;
    text-align: center;
}

.tbl_set1 tr {
    border: none;
    border-bottom: 1px solid #ddd !important;
}

.tbl_set1 {
    max-height: 360px;
    overflow-y: auto;
    padding-right: 1.5rem;
}

.card_wrap_h {
    min-height: 120px;
    line-height: 1.2;
}

.cs_tabs.nav-tabs .nav-item .nav-link {
    font-size: 18px;
    font-weight: 600;
    padding-left: 0;
    padding-right: 0;
    border: none !important;
}

.cs_tabs.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: var(--blue);
}

.op_1 {
    opacity: 1 !important;
}

.table_cmp_dashboard thead th,
.table_cmp_dashboard tr td {
    padding: 1rem 10px;
}

.table_cmp_dashboard th:nth-child(2) {
    width: auto;
}

.table_cmp_dashboard th {
    font-weight: 500;
}

/*===============Notification Only Style============*/

.dropdown-menu.notification {
    padding: 0.7rem;
}

.dropdown-menu.notification:before {
    content: '';
    border: 1px solid transparent;
    border-width: 15px 15px 0 15px;
    position: absolute;
    top: -11px;
    right: -10px;
    border-top-color: white;
    transform: rotate(-45deg);
}

.dropdown-menu.notification::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.dropdown-menu.notification::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5;
}

.dropdown-menu.notification::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border: 2px solid #ccc;
}

.dropdown-menu.notification .notihead {
    padding: 0.7rem 1rem 0.5rem !important;
    border-bottom: 1px solid #ccc;
    font-weight: 500;
}

.dropdown-menu.notification .notihead a {
    font-weight: 400;
}

.notification .noti {
    border-bottom: 1px solid #ddd;
}

.dropdown-menu.notification .noti>a {
    display: block;
    flex: 0 0 50px;
    max-width: 50px;
    padding: 0
}

.dropdown-menu.notification .left.col-md-3 {
    flex: 0 0 50px;
    max-width: 50px;
    height: 50px;
    padding: 0;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid #ccc;
    width: auto;
}

.dropdown-menu.notification .notiunread {
    background-color: #fff;
    color: #212529 !important;
}

.dropdown-menu.notification .left.col-md-3 img {
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    position: absolute;
    object-fit: cover;
    transform: translate(-50%, -50%);
    max-height: 90%;
    max-width: 90%;
    object-fit: scale-down;
}

.right.col-md-7.d-flex.align-items-center {
    flex: 1 0 0%;
    flex-flow: column;
    align-items: flex-start !important;
}

.right.col-md-7.d-flex.align-items-center .text-secondary {
    font-size: 80%;
    color: #a5a0a0;
}

/*=============Notification Only Style End============*/

.text_all_white * {
    color: #fff;
}

.bg_bl2 {
    background: #344AB9 !important;
}

.btn-block {
    width: 100%;
}

.card_lists a {
    text-decoration: none;
}

/*==========RE0016=======*/

a {
    text-decoration: none;
}

.lh_12 {
    line-height: 1.2;
}

.cs_row2 {
    margin-left: -5px;
    margin-right: -5px;
}

.cs_row2>.col,
.cs_row2>[class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
}

.p_12 {
    padding: 12px !important;
}

.px_12 {
    padding-left: 12px !important;
    padding-right: 12px !important;
}

.tbl_style thead {
    box-shadow: none;
}

.cndi_tble th:last-child {
    width: 43%;
}

.cndi_tble th:first-child,
.cndi_tble td:first-child {
    text-align: left;
}

.cndi_tble th,
.cndi_tble td {
    border-bottom: 1px solid #ddd;
}

.cs_shadow {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.15);
}

.tbl_style th {
    font-weight: 500;
    border-bottom-color: #c5c5c5;
}

.tbl_style tbody tr:last-child td {
    border-bottom: none;
}

#kyc .modal-dialog {
    max-width: 850px;
}

#kyc ol li {
    margin-bottom: 10px;
}

/*Testimonial ===========*/

.star-rating {
    direction: rtl;
    display: inline-block;
    cursor: default;
}

.star-rating input[type=radio] {
    display: none;
}

.star-rating label {
    color: #bbb;
    font-size: 1.2rem;
    padding: 0;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.star-rating label:hover,
.star-rating label:hover~label,
.star-rating input[type=radio]:checked~label {
    color: var(--blue);
}

#testimonial .modal-dialog {
    max-width: 700px;
}

select.form-select {
    padding-right: 1.75rem;
}

/*=========Bug Fixes==============*/

.cs_clst {
    flex: 1 0 18%;
    max-width: 18%;
}

.cs_clst2 {
    flex: 1 0 12%;
    max-width: 12%;
}

.mt_32 {
    margin-top: 32px !important;
}

/*===========*/

.cs_h4 {
    font-size: 24px;
}

.lc_item svg,
.lc_item img {
    vertical-align: bottom;
    margin-right: .25rem !important;
}

.lc_item h5 {
    font-size: 1.35rem;
    font-weight: 500;
    line-height: 1;
}

.wrpper_block .cs_card.bg_bl2 a {
    opacity: 0.75;
    transition: 0.12s;
}

.wrpper_block .cs_card.bg_bl2 a:hover {
    opacity: 1;
    color: #fff;
}

.lc_item p {
    padding-top: 5px;
    line-height: 1.25;
}

.cm_cd_style img {
    filter: brightness(0);
    margin-right: 4px;
}

.cm_cd_style strong {
    font-weight: 500;
}

.cm_cd_style li {
    justify-content: space-between;
    padding: 1rem 0;
    border-bottom: 1px solid #ddd;
    display: flex;
}

.cm_cd_style li span:last-child {
    flex: 0 0 35%;
    max-width: 35%;
    padding-left: 10px;
    text-align: right;
}

.cm_cd_style li:last-child {
    border-bottom: 0;
    padding-bottom: 0;
}

/*-=======Company List========*/

.lable_style label {
    font-family: inherit;
    font-size: inherit;
}

.btn_sm {
    font-size: 14px !important;
    padding: 7.5px 1rem !important;
}

.btn_icon {
    height: 38px;
    line-height: 38px;
    padding: 5px 12px !important;
    vertical-align: bottom;
}

.btn_icon i {
    vertical-align: super;
}

.fw_500 {
    font-weight: 500 !important;
}

.themebtn.btn_xm {
    padding: 5px 9px !important;
    font-size: 12px !important;
}

.cs_table_style th {
    font-weight: 500;
    vertical-align: middle;
    padding: 1rem .5rem;
    vertical-align: middle;
    /*    text-align: center*/
}

.cs_table_style td {
    padding: 1rem .5rem;
    vertical-align: middle;
    /*    text-align: center*/
}

.cs_table_style th:last-child,
.cs_table_style td:last-child {
    //text-align: right;
}

.cs_table_style tbody td {
    border-bottom: 1px solid #ddd !important;
    border-bottom: none !important;
}

.cs_table_style tbody tr:hover {
    background: #F7F7F7;
}

.cs_table_style th i {
    font-size: 80%;
}

.bg_light {
    background: #ECF0F4;
}

.fz_14_all * {
    font-size: 14px !important;
}

.cs_search_wrpper [type="submit"] {
    background: #ddd !important;
}

#student_profile .modal-dialog {
    max-width: 850px;
}

/*
.table_eq_w td,
.table_eq_w th {
    width: 20%;
}
*/

.table_eq_w td:first-child,
.table_eq_w th:first-child {
    width: 4%;
    padding-right: 0;
}

.table_eq_w td:last-child,
.table_eq_w th:last-child {
    width: 15%;
}

.table_eq_w td:nth-child(4),
.table_eq_w th:nth-child(4),
.table_eq_w td:nth-child(5),
.table_eq_w th:nth-child(5),
.table_eq_w td:nth-child(6),
.table_eq_w th:nth-child(6) {
    width: 13%;
}

.table_eq_w td:nth-child(3),
.table_eq_w th:nth-child(3) {
    width: 24%;
}

.table_scroller {
    max-height: 425px;
    overflow-y: auto;
}

body.modal-open {
    height: 100vh;
}

/*==============Add Quize===============*/

.fw_500p {
    font-weight: 500 !important;
    text-shadow: 0 0 #333333 !important;
}

.move_symbol {
    position: relative;
    width: 30px;
    display: inline-block;
    cursor: move;
}

.move_symbol:after,
.move_symbol:before {
    content: '';
    border: 7px solid transparent;
    border-right: 11px solid currentcolor;
    /* position: absolute; */
    display: inline-block;
    transform: rotate(90deg);
    border-width: 5px 9px 5px 0;
    position: absolute;
    left: 5px;
    top: 3px;
}

.move_symbol:after {
    transform: rotate(270deg);
    right: 6px;
    left: auto;
    top: 3px;
}

.col_1p {
    flex: 0 0 14.5%;
    max-width: 14.5%;
}

.col_3p {
    flex: 0 0 29%;
    max-width: 29%;
}

.col_4p {
    flex: 0 0 35%;
    max-width: 35%;
}

.col_2p {
    flex: 0 0 20%;
    max-width: 20%;
}

.cancle_row {
    color: inherit;
}

/*==============Add Quize===============*/

.table_align_set th:not(:nth-child(2),
    :last-child),
.table_align_set td:not(:nth-child(2),
    :last-child) {
    text-align: center;
}

.table_align_set1 th:not(:first-child,
    :last-child),
.table_align_set1 td:not(:first-child,
    :last-child) {
    text-align: center;
}

/*===============IN003=================*/

.tooltip-main {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    font-weight: 700;
    background: #f3f3f3;
    border: 1px solid #737373;
    color: #737373;
    margin: 4px 121px 0 5px;
    float: right;
    text-align: left !important;
}

.tooltip-qm {
    float: left;
    margin: -2px 0px 3px 4px;
    font-size: 12px;
}

.tooltip-inner {
    max-width: 236px !important;
    font-size: 12px;
    padding: 10px 15px 10px 20px;
    background: #FFFFFF;
    color: rgba(0, 0, 0, .7);
    border: 1px solid #ccc;
    text-align: left;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.tooltip.show {
    opacity: 1;
}

.table_type2 th {
    font-weight: 500;
    vertical-align: middle;
    padding: 1rem;
    vertical-align: middle;
    background: var(--blue);
    color: #fff;
}

.table_type2 tbody td {
    border-bottom: 1px solid #eee !important;
    padding: 1rem;
    vertical-align: middle;
    /* text-align: center; */
}

.bg_light2 {
    background: #FAFAFA;
}

.invoice_section a {
    color: inherit !important;
}

.invoice_section {
    color: #707070;
}

.cs_h6 {
    font-size: 16px;
    font-weight: 500;
}

.purple_head th {
    background: var(--purple) !important;
}

.mx_n_4 {
    margin: 0 -24px;
}

.bd_none tr td,
.bd_none tr th {
    border: none !important;
}

.table tfoot th {
    background: transparent !important;
    color: inherit;
}

.table_type3 th,
.table_type3 td {
    padding: .65rem 1rem !important;
}

.cs_h2 {
    font-size: 35px;
}

.bg_yellow {
    background: var(--yellow);
}

.cs_h3 {
    font-size: 28px;
}

.cs_h5p {
    font-size: 22px;
}

.cs_h4p {
    font-size: 26px;
}

.option_block label {
    font-size: inherit;
}

.cs_card img {
    max-width: 100%;
}

/*==============================*/

.player {
    width: 74px;
    height: 74px;
    background: #fff;
    position: relative;
    border-radius: 50%;
    overflow: visible;
}

.player:hover {
    opacity: .8;
}

.left-side {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 7px solid #e1e6fc;
    border-left-color: transparent;
    border-bottom-color: transparent;
    transform: rotate(45deg);
    box-shadow: inset -1px 1px #ffffff, 2px -2px white
}

.left-side.fill {
    border-color: transparent;
    border-left-color: var(--blue);
    border-bottom-color: var(--blue);
}

.right-side {
    transform: rotate(45deg);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 50%;
    border: 7px solid var(--blue);
    border-left-color: #e1e6fc;
    border-bottom-color: #e1e6fc;
}

.player-text {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    text-align: center;
    color: #999;
    transform: translateY(-50%);
}

.qq_count_wrap .cs_qq {
    width: 35px;
    height: 35px;
    border: 1px solid var(--blue);
    border-radius: 50%;
    text-align: center;
    margin: 5px 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.q_done {
    background: var(--blue);
   
}

.q_done:after {
    content: '';
    width: 16px;
    height: 7px;
    position: absolute;
    left: 8px;
    top: 12px;
    border: 3px solid #fff;
    border-width: 0 0 2px 2px;
    transform: rotate(-45deg);
}

.q_current {
    color: var(--blue);
    border-width: 2px !important;
    -webkit-animation: palyButton 1.75s infinite ease-in-out;
    -moz-animation: palyButton 1.75s infinite ease-in-out;
    -ms-animation: palyButton 1.75s infinite ease-in-out;
    -o-animation: palyButton 1.75s infinite ease-in-out;
    animation: palyButton 1.75s infinite ease-in-out
}

@-webkit-keyframes palyButton {
    0% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(15, 105, 194, 0)
    }
}

@-moz-keyframes palyButton {
    0% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(15, 105, 194, 0)
    }
}

@-ms-keyframes palyButton {
    0% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(15, 105, 194, 0)
    }
}

@-o-keyframes palyButton {
    0% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(15, 105, 194, 0)
    }
}

@keyframes palyButton {
    0% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(15, 105, 194, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(15, 105, 194, 0)
    }
}

/*----------------Edit Profile----------------*/

.shadow_sm {
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.edit_prof_style_list {
    padding-left: 0;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    line-height: 1.4;
}

.edit_prof_style_list li {
    flex: 0 0 46%;
    max-width: 46%;
    padding: 4px 0;
}

.edit_prof_style_list li>span,
.edit_prof_style_list li>strong {
    display: block;
}

/*--------------Re0030--------------*/

.cs_tabs2 {
    border-bottom: 1px solid #111 !important;
}

.cs_tabs2.nav-tabs .nav-item .nav-link.active {
    border-bottom: 3px solid var(--blue) !important;
}

.normal_table td,
.normal_table th {
    vertical-align: middle;
}

.normal_table td:last-child,
.normal_table th:last-child {
    text-align: right;
}

.cs_modal2 p {
    margin-bottom: 1rem;
}

.cs_modal2 h4,
.cs_modal2 h5,
.cs_modal2 h3 {
    margin-bottom: 5px;
    line-height: 1.2;
}

/*-----------RE0031-----------*/

.probable_candidate {
    right: 30.33%;
    position: fixed;
}

/*-----------RE0032-------------*/


/*accordion*/

.cs_accordion [aria-expanded] {
    transition: all 0.2s;
    position: relative;
    font-weight: 500;
    border-bottom: 1px solid transparent;
    color: #555;
}

.loadMore_btn {
    display: none;
}

.cs_accordion .card-header {
    border-radius: 0 !important;
    padding-left: 0;
    padding-right: 0;
}

.cs_accordion .card-body {
    padding: 0 0 1rem;
}

.cs_accordion [aria-expanded]:after,
.cs_accordion [aria-expanded]:before {
    content: '';
    position: absolute;
    width: 16px;
    height: 2px;
    background: currentColor;
    right: 5px;
    top: calc(50% - 2px);
    transition: 0.35s;
}

.cs_accordion [aria-expanded]:before {
    transform: rotate(90deg);
}

.cs_accordion [aria-expanded="true"]:before {
    background: var(--blue);
    transform: rotate(180deg);
}

.cs_accordion [aria-expanded="true"]:after {
    background: transparent;
    transform: rotate(45deg);
}

.cs_accordion [aria-expanded="true"] {
    /*    border-bottom-color: var(--blue);*/
    color: var(--blue);
}

.cs_accordion .card-header {
    background: transparent;
    border: 0;
}

.cs_accordion .card {
    border: 1px solid #ccc !important;
    border-width: 1px 0 0px !important;
    border-radius: 0 !important;
}

.cs_accordion .card:first-child {
    border-top: 0 !important;
}

.cs_cols {
    margin-bottom: 0;
}

.cs_cols>li {
    padding: 0 5px;
    flex: 0 0 20%;
    max-width: 20%;
}

.symbol_position .move_symbol,
.symbol_position .cross_symbol {
    left: calc(100% - 28px);
    top: 1rem;
    position: absolute;
    opacity: 1;
}

.symbol_position .cross_symbol {
    left: calc(100% + 4px);
    top: 0.75rem;
}

.draggable_item.symbol_position {
    padding-right: 2rem !important;
}

/*----------RE0033----------*/

.banner_img_wrapper {
    height: 223px;
    background: #3FBDBB;
}

.banner_img_wrapper img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

// .banner_img_wrapper img:empty {
//     display: none;
// }

.list_flex {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.list_flex>li {
    display: flex;
}

.list_flex>li>p i {
    width: 20px;
    display: inline-block;
    vertical-align: middle;
}

.list_flex>li>p:first-child {
    flex: 0 0 40%;
    padding-right: 1rem;
    font-weight: 500;
}

.cs_drp_set {
    position: relative;
}

.cs_drp_set .cs_drp_content {
    padding: 1.75rem 1rem 1rem;
    display: block;
    position: absolute;
    width: 230px;
    right: 0;
    top: 0;
    background: #fff;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    display: none;
}

.cs_drp_set .btn-close {
    position: absolute;
    right: 10px;
    top: 10px;
    background-size: 12px;
}

.cs_nav {
    display: inline-block;
    width: 25px;
    height: 20px;
    position: relative;
    color: #ccc;
}

.cs_nav:after {
    content: '';
    border: 7px solid transparent;
    border-right-color: currentcolor;
    position: absolute;
    border-left: none;
    left: 7px;
    top: 3px;
}

.cs_next:after {
    border-left: 7px solid currentcolor;
    border-right: none;
}

.cs_nav.hover {
    color: var(--blue);
}

.drp_content_wrapper {
    display: none;
}

.drp_content_wrapper.active {
    display: block;
}

.fail_sign,
.pass_sign {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background: #FD6A69;
    vertical-align: bottom;
}

.pass_sign {
    background: #3FBDBB;
}

.card_wrapper_height {
    max-height: calc(100vh - 200px);
    overflow: auto;
    margin: -1rem;
    padding: 1rem;
}

/*------------CA0014----------*/

.cs_select_style {
    border: 1px solid var(--blue);
    border-radius: 4px;
    position: relative;
}

.cs_select_style .form-select {
    display: inline-block !important;
    width: auto !important;
    border: none !important;
    color: var(--blue) !important;
    background: transparent !important;
}

.cs_select_style span {
    border-right: 1px solid #437ad980;
    line-height: 1;
}

.cs_select_style:after {
    content: '';
    position: absolute;
    right: 10px;
    top: 10px;
    width: 8px;
    height: 8px;
    border: 1px solid currentcolor;
    border-width: 1px 1px 0 0;
    transform: rotate(135deg);
}

.mnwth_img {
    min-width: 70px;
}

/*--------------RE0036------------*/

.cs_table thead {
    box-shadow: 0 .5px 1px var(--medium-gray)
}

.cs_table.table-bordered>:not(caption)>* {
    border-width: 1px 0;
    border-color: #ccc;
}

.f_td_bold td:first-child {
    font-weight: 500;
}

.table.style2 tbody tr+tr td {
    border-top: 1px solid #ddd !important;
}

.table.style2 tr td,
.table.style2 tr th {
    padding: 1rem !important;
}

.table_middle {
    text-align: center;
}

.middle_text td:first-child,
.table_middle td:first-child,
.middle_text th:first-child,
.table_middle th:first-child {
    text-align: left;
}

.table_middle td:last-child,
.table_middle th:last-child {
    //text-align: right;
}

.bg_lightgray {
    background: #f1f1f1;
}

.btn_xsm,
.btn-xsm {
    padding: 7px 14px !important;
    font-size: 13px !important;
}

.table.style3 tr td,
.table.style3 tr th {
    padding: .5rem 1rem !important;
}

.table.style3 tr th {
    padding: .75rem 1rem !important;
}

.text_danger {
    color: #fd1e23;
}

.bd_bl {
    border-color: var(--blue);
}

.filter_sm {
    padding: 6px 8px !important;
}

.cs_row3 {
    margin-left: -3px;
    margin-right: -3px;
}

.cs_row3>.col,
.cs_row3>[class*=col-] {
    padding-left: 3px;
    padding-right: 3px;
}

[type='checkbox'] {
    box-shadow: none !important;
}

.themebtn3,
.themebtn3:active,
.themebtn3:focus {
    display: inline-block;
    text-decoration: none !important;
    outline: none !important;
    border-radius: 4px;
    background: #FA606014;
    color: #D95544;
    padding: 9px 2rem;
    border: 1px solid #D95544;
    transition: 0.2s;
    text-transform: capitalize;
    cursor: pointer;
    text-align: center;
}

.themebtn3:hover {
    background: #D95544;
    color: #fff;
    border-color: #D95544;
}

.active_status {
    position: relative;
    /*    padding-left: 1rem;*/
}

.active_status:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    background: #21D512;
    border-radius: 50%;
    margin-right: 7px;
    vertical-align: middle;
}

.geo_list_style li,
.geo_list_style2 li {
    text-transform: capitalize;
    background: #f5f5f5;
    margin-bottom: 8px;
    padding: 8px 1rem;
}

.geo_list_style2 li {
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.table_checkbox th:first-child,
.table_checkbox th:nth-child(2),
.table_checkbox td:first-child,
.table_checkbox td:nth-child(2) {
    text-align: left;
}

.form_height .form-control,
.form_height .form-select,
.form_height .themebtn {
    height: 38px !important;
}

.eye_symbol,
.eye_close_symbol {
    position: relative;
    width: 18px;
    height: 18px;
    border: 1px solid currentcolor;
    display: inline-block;
    border-radius: 100% 3px;
    transform: rotate(45deg);
}

.eye_symbol:before,
.eye_close_symbol:before {
    position: absolute;
    content: '';
    width: 7px;
    height: 7px;
    background: currentcolor;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    border: 2px solid transparent;
    box-shadow: 0 0px 0 1px currentColor;
    background-clip: content-box;
}

.eye_close_symbol:after {
    content: '';
    position: absolute;
    height: 2px;
    width: 23px;
    background: currentcolor;
    transform: rotate(87deg);
    top: 7px;
    left: -3px;
}

.table_striped tbody tr {
    background: #ECF0F4;
}

.table_striped tbody tr:nth-child(odd):not(:hover) {
    background: #f5f5f5
}

.table.last_end th:last-child,
.table.last_end td:last-child {
    // text-align: right;
}

.table.table_striped tbody tr:hover {
    background: #F7F7F7;
}

/*-----------RE0024 - 27------------*/

.submit_bnt {
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 0 4px 4px 0 !important;
}

.arrow_up,
.arrow_down {
    display: inline-block;
    width: 18px;
    height: 16px;
    vertical-align: middle;
    position: relative;
}

.arrow_up:before,
.arrow_down:before {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border: 1px solid currentcolor;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
    left: 5px;
    top: 2px;
}

.arrow_up:before,
.arrow_down[aria-expanded="true"]:before {
    border-width: 1px 0 0 1px;
    top: 6px;
}

.cs_table_popup {
    left: 0;
    position: absolute;
    width: 100%;
    padding: 1rem;
    background: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1;
}

.table {
    position: relative;
}

.action_table th:last-child,
.action_table td:last-child {
    min-width: 75px;
}

.cs_link:not(:hover) {
    color: inherit;
}

.cs_job_card {
    position: relative;
}

.job_card_header * {
    font-size: 14px !important;
}

.job_card_header .cs_flex_item+.cs_flex_item {
    margin: 0 0 0 5px !important;
}

.job_card_header .themebtn {
    padding: 5px 10px !important;
    line-height: 2 !important;
}

.job_card_header select {
    flex-grow: 1;
}

.job_card_header .cs_search_F {
    flex: 1 0 35%;
    max-width: 45%;
}

.job_card_header .themebtn {
    white-space: nowrap;
}

.cs_flex_item button {
    height: 38px !important;
    color: inherit !important;
}

.cs_flex_item .dropdown:not(:hover) button {
    border-color: #ced4da !important;
}

.cs_flex_item .dropdown-menu {
    width: 100%;
}

.filter_btn_wrap,
.filter_btn_wrap .filter-btn {
    width: 100%;
}

.msg_header_bar {
    line-height: 1.2;
}

.cs_to_msg {
    display: flex;
}

.tofield_input {
    display: flex;
    flex-wrap: wrap;
}

.tofield_input input,
.search_dd_wrap input {
    border: none !important;
    width: auto !important;
    box-shadow: none !important;
    display: inline-block;
    vertical-align: middle;
    outline: none !important;
    height: 27px;
}

.input_to_csi_warpper {
    position: relative;
}

.user_badge {
    padding: 4px 30px 5px 10px;
    border: 1px solid #ccc;
    border-radius: 20px;
    vertical-align: middle;
    position: relative;
    background: #f8f8f8;
    line-height: 1;
    margin-right: 10px;
    margin-bottom: 2px;
}

.user_badge:hover {
    background: #eee;
}

.user_badge .cancel {
    vertical-align: middle;
    top: 0px;
    right: 3px;
    position: absolute;
    background-size: 9px;
}

.msg_header_bar strong {
    font-weight: 500;
}

.dropdown_user {
    display: none;
    top: 100%;
    border-radius: 5px;
    /*    box-shadow: 0 1px 3px 0 rgb(60 64 67 / 30%), 0 4px 8px 3px rgb(60 64 67 / 15%);*/
    box-shadow: 0 3px 8px rgba(60, 64, 67, 0.3);
    position: absolute;
    left: 0;
    width: 300px;
    background: #fff;
    z-index: 3;
    padding: 10px 0;
    max-height: 350px;
    overflow-y: auto;
}

.dropdown_user.right {
    left: auto;
    right: 0;
}

@-moz-document url-prefix() {
    .style_5 {
        scrollbar-color: #ccc #eee;
        scrollbar-width: thin;
    }
}

.dropdown_user.active {
    display: block;
}

.dropdown_user ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.dropdown_user ul li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 10px 1rem;
    /*    background: #000;*/
    transition: 0.1s;
}

.dropdown_user ul li:hover {
    background: #f7f7f7;
}

.user_to_thumb {
    flex: 0 0 38px;
    max-width: 38px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.search_dd_wrap {
    position: relative;
}

.search_dd_wrap .dropdown_user a,
.dropdown_user p {
    text-decoration: none;
    color: inherit;
    display: block;
    padding: 6px 1rem;
    margin-bottom: 0;
}

.search_dd_wrap .dropdown_user,
.drp_warpper .dropdown_user {
    width: 100%;
}

.search_dd_wrap .dropdown_user a:hover,
.dropdown_user p:hover {
    background: #eee;
}

.search_dd_wrap input {
    width: 100% !important;
}

.contry_code_box2{
    border: 1px solid #ced4da;
        border-radius: 4px;
        display: flex;
        position: relative;

}

.contry_code_box2 input {
    height: 20px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 3px 7px;
    flex: 1 1 100%;
}

.contry_code_box2 input.c_code {
    padding: 4px 1rem 4px 3px;
    border-right: 1px solid #eee !important;
    width: 100%;
    text-align: right;
}

.contry_code_box2 .c_code_warp {
    flex: 0 0 62px;
    max-width: 62px;
    position: relative;
}

.contry_code_box2 .c_code_warp:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 10px;
    width: 4px;
    height: 4px;
    border: 1px solid;
    border-width: 1px 1px 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    pointer-events: none;
}

.contry_code_box2 .dropdown_user {
    border-radius: 0 0 5px 5px;
    font-size: 13px;
    min-width: 100%;
}

.contry_code_box {
    border: 1px solid #ced4da;
    border-radius: 4px;
    display: flex;
    position: relative;
}

.contry_code_box input {
    height: 40px;
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    padding: 8px 12px;
    flex: 1 1 100%;
}

.contry_code_box input.c_code {
    padding: 8px 1rem 8px 3px;
    border-right: 1px solid #eee !important;
    width: 100%;
    text-align: right;
}

.contry_code_box .c_code_warp {
    flex: 0 0 58px;
    max-width: 58px;
    position: relative;
}

.contry_code_box .c_code_warp:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 17px;
    width: 5px;
    height: 5px;
    border: 1px solid;
    border-width: 1px 1px 0 0;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    pointer-events: none;
}

.contry_code_box .dropdown_user {
    border-radius: 0 0 5px 5px;
    font-size: 13px;
    min-width: 100%;
}

.q_opt {
    list-style-type: none;
    padding-left: 14px;
    margin-bottom: 10px;
}

.q_opt li {
    padding: 4px 0;
    font-weight: 500;
}

.abc_list {
    list-style: upper-alpha;
}

.abc_list li {
    padding: 4px 0;
}

.ans_container .abc_list [type='text'] {
    width: 76px;
    margin-left: 1rem;
    text-transform: uppercase;
    text-align: center;
    border-radius: 0;
}

.drp_warpper .dropdown_user {
    border-radius: 0 0 5px 5px;
}


/*==============Payment =================*/

.text_green {
    color: #05ab05 !important;
}

.right_icon_circle {
    position: relative;
    color: green;
    width: 50px;
    height: 50px;
    color: #05ab05;
    border: 3px solid CurrentColor;
    display: inline-block;
    border-radius: 50%;
    background: CurrentColor;

    animation: blickAnimate 1.75s infinite ease-in-out
}

.right_icon_circle:after {
    content: '';
    width: 30px;
    height: 11px;
    position: absolute;
    border: 2px solid #fff;
    top: 15px;
    left: 8px;
    border-width: 0 0px 3px 3px;
    transform: rotate(322deg);
}

@keyframes blickAnimate {
    0% {
        box-shadow: 0 0 0 0 rgba(5, 171, 5, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(5, 171, 5, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(5, 171, 5, 0)
    }
}

.crl_cross {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: red;
    animation: redBlick 1.75s infinite ease-in-out
}

.crl_cross .cross_symbol {
    background-size: 24px;
    filter: brightness(0) invert(1);
    opacity: 1;

}

@keyframes redBlick {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, .5)
    }

    25% {
        box-shadow: 0 0 0 0 rgba(255, 0, 0, .5)
    }

    100% {
        box-shadow: 0 0 0 15px rgba(255, 0, 0, 0)
    }
}

.mnh_set {
    min-height: 175px;
}

.lh_15 {
    line-height: 1.5;
}

.text_gray {
    color: #666;
}

/*==================================================
=====================Responsive=====================
==================================================*/

@media all and (min-width:1200px) {
    .cs_modal2 .modal-dialog {
        max-width: 650px;
        margin: 1.75rem auto;
    }

    .cs_modal .modal-dialog {
        max-width: 1150px;
        margin: 1.75rem auto;
    }
    .cs_modal::-webkit-scrollbar{
        display: none;
    }
}


.twdt-15 {
    width: 15%;
}

.twdt-5 {
    width: 5%;
}

.twdt-60 {
    width: 50%;
}


.nav-components a {
    color: #333;
    padding-bottom: 14px;
    font-weight: 500;

    /* border-bottom: 5px solid #1874AA; */

}

.nav-components a:hover {
    padding-bottom: 14px;
    font-weight: 600;
    border-bottom: 5px solid #1874AA;

}

.nav-components {
    margin-right: 3%;
    font-size: larger;
    color: black;
    font-family: 'Roboto', sans-serif;
}

.aboutus-contentin {
    width: 40vw;

}

.aboutus-Content1 {
    font-size: 0.9375em;
    line-height: 22px;
    font-family: 'Roboto', sans-serif;
}

.aboutus-contentin {
    width: 75vw;

}

.aboutus-contentin2 {
    padding: 20px 0px 0px 0px;
    width: 40vw;
}

.aboutus-img1 img {
    width: 100%;
    max-width: 90%;
    height: auto;
}

.content {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.aboutus-content2 {
    align-items: center;
    display: flex;
    justify-content: space-around;
    /* margin: 5%; */
    font-family: 'Roboto', sans-serif;
    font-size: .875em;
}

.aboutus-img2 img {
    width: 40vw;
    /* width: 100%; */
    /* height: 270px;
    width: 635px; */
}

.aboutus-content3 {
    display: flex;
    justify-content: space-around;
    margin: 5%;
    background-color: #F2FBFF;
    align-items: center;
    padding: 3%;
    font-family: 'Roboto', sans-serif;
}

.aboutus-img3 img {
    width: 18vw;
}

.capital-heading {
    font-size: 1.2em;
    /* font-weight: 500; */
}

.capital {
    width: 50vw;
}

.aboutus-content4 {
    text-align: center;
    color: #1874AA;
    font-family: 'Roboto', sans-serif;
}

.aboutus-Card1 {
    display: flex;
    justify-content: space-around;
    font-family: 'Roboto', sans-serif;

}

.aboutus-card {
    display: flex;
    justify-content: space-between;
    padding: 3%;
    margin-top: 5%;
    font-family: 'Roboto', sans-serif;
}

.img-card {
    width: 9vw;
}

.vision-text p {
    font-size: 0.775em;
}

.Team-card {
    display: flex;
    /* justify-items: flex-end; */
    flex-direction: column-reverse;
}

.team-card-img img {
    height: 110px;
    width: 110px;
}

.Team-content {
    text-align: left;
    margin-left: 5%;
    list-style-type: none;
    font-family: 'Roboto', sans-serif;
}

.Team-card1 {
    height: 110px;
    width: 110px;
}

.Team-card-sub {
    height: 110px;
    width: 110px;
    text-align: center;
    font-weight: bold;
    color: black;
    border-radius: 50%;
    border: 5px solid #02d8cd;
    margin: 4px;
}

.Team-Card {
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    padding: 3%;
    margin: 2%;
    font-family: 'Roboto', sans-serif;
}

.team-card-main {
    display: flex;
    justify-content: space-between;
    font-family: 'Roboto', sans-serif;
}

.Keyteam-heading {
    text-align: center;
    font-weight: bolder;
    color: #0C6CA5;
    font-family: 'Roboto', sans-serif;

}

.Team-subpart {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex: 1;
    font-family: 'Roboto', sans-serif;
}


.team-subpart-img {
    flex: 0.2;
}


.team-subpart-text {
    flex: 0.9;
    list-style: none;
    text-align: left;
    font-family: 'Roboto', sans-serif;
}


.Team-subpart-main {
    border-radius: 10px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    text-align: center;
    padding: 3%;
    margin: 2%;
    font-family: 'Roboto', sans-serif;
}

.team-subpart-text1 {
    font-weight: bold;
    font-family: 'Roboto', sans-serif;
}



.team-content-ul li {
    padding-bottom: 5px;
}

.team-content-ul {
    font-size: 0.775em;
    list-style: none;
    padding: unset;
}


/* ................................................................. */

#news-slider {
    margin-top: 80px;
}

.post-slide {
    background: #fff;
    margin: 20px 15px 20px;
    border-radius: 15px;
    padding-top: 1px;
    box-shadow: 0px 14px 22px -9px #bbcbd8;
}

.post-slide .post-img {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    margin: 10px 1px 0px 0px;
    /* margin-left: -10px; */
}

.post-slide .post-img img {
    width: 100%;
    /* height: auto; */
    transform: scale(1, 1);
    transition: transform 0.2s linear;
}

.post-slide:hover .post-img img {
    transform: scale(1.1, 1.1);
}

.post-slide .over-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    /* background: linear-gradient(-45deg, rgba(6,190,244,0.75) 0%, rgba(45,112,253,0.6) 100%); */
    transition: all 0.50s linear;
}

.post-slide:hover .over-layer {
    opacity: 1;
    text-decoration: none;
}

.post-slide .over-layer i {
    position: relative;
    top: 45%;
    text-align: center;
    display: block;
    color: #fff;
    font-size: 25px;
    font-family: 'Roboto', sans-serif;
}

.post-slide .post-content {
    background: #fff;
    padding: 2px 20px 60px;
    border-radius: 15px;
    font-family: 'Roboto', sans-serif;
}

.post-slide .post-title a {
    font-size: 15px;
    font-weight: bold;
    color: #333;
    display: inline-block;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    font-family: 'Roboto', sans-serif;
}

.post-slide .post-title a:hover {
    text-decoration: none;
    color: #3498db;
}

.post-slide .post-description {
    line-height: 24px;
    color: #808080;
    margin-bottom: 25px;
}

.post-slide .post-date {
    color: #a9a9a9;
    font-size: 14px;
}

.post-slide .post-date i {
    font-size: 20px;
    margin-right: 8px;
    color: #CFDACE;
}

.post-slide .read-more {
    padding: 7px 20px;
    /* float: left; */
    font-size: 12px;
    background: #2196F3;
    color: #ffffff;
    box-shadow: 0px 10px 20px -10px #1376c5;
    border-radius: 25px;
    text-transform: uppercase;
}

.post-slide .read-more:hover {
    background: #3498db;
    text-decoration: none;
    color: #fff;
}

.owl-controls .owl-buttons {
    text-align: center;
    margin-top: 20px;
}

.owl-controls .owl-buttons .owl-prev {
    background: #fff;
    position: absolute;
    top: -13%;
    left: 15px;
    padding: 0 18px 0 15px;
    border-radius: 50px;
    box-shadow: 3px 14px 25px -10px #92b4d0;
    transition: background 0.5s ease 0s;
}

.owl-controls .owl-buttons .owl-next {
    background: #fff;
    position: absolute;
    top: -13%;
    right: 15px;
    padding: 0 15px 0 18px;
    border-radius: 50px;
    box-shadow: -3px 14px 25px -10px #92b4d0;
    transition: background 0.5s ease 0s;
}

.owl-controls .owl-buttons .owl-prev:after,
.owl-controls .owl-buttons .owl-next:after {
    content: "\f104";
    font-family: FontAwesome;
    color: #333;
    font-size: 30px;
}

.owl-controls .owl-buttons .owl-next:after {
    content: "\f105";
}



.newsroom-heading {
    text-align: center;
    font-weight: bolder;
    color: #0C6CA5;
    font-family: 'Roboto', sans-serif;
}

.vision-text {
    text-align: left;
    margin-left: 7%;
    font-family: 'Roboto', sans-serif;
    color: black;

}

.headingin {
    display: flex;
}

// about us css
.navbar1 {
    display: flex;
    justify-content: left;
    margin: 2%;
    list-style-type: none;
    padding: 1%;
    border-bottom: 1px solid black;

}

.nav-components a {
    color: #333;
    padding-bottom: 14px;
    font-weight: 500;

    /* border-bottom: 5px solid #1874AA; */

}

.nav-components a:hover {
    padding-bottom: 14px;
    font-weight: 600;
    border-bottom: 5px solid #1874AA;

}

.nav-components {
    margin-right: 3%;
    font-size: larger;
    color: black;
    font-family: 'Roboto', sans-serif;
}



.importHover:hover .showInstruction {
    display: block !important;
    font-size: 12px !important;
    font-weight: normal !important;
    font-family: 'Roboto', sans-serif !important;
    background-color: rgb(231, 220, 159) !important;
    color: black !important;
    padding: 5px !important;
    z-index: 100 !important;
    left: 40px !important;
    border-radius: 5px;
}

.showInstruction {
    display: none;
}


/* 15 November, 2022 */
p {
    margin-bottom: 1rem !important;
}


.list_flex>li>p:first-child {
    margin-bottom: 1rem !important;
}

ul.dropdown-menu {
    max-height: 500px;
    overflow-y: auto;
}

// ul.dropdown-menu::-webkit-scrollbar {
//     display: none;
// }

ul.pagination.justify-content-center {
    margin-top: 30px;
}

ul.pagination.justify-content-center ul li a {
    transition: .5s ease;
}

.text-sm {
    font-size: 90%;
    color: gray;
}

.fileFocous>input:focus+label {
    /* targets the label when the input receives focus */

    border: 1px solid black;

}

.prof-img-edit-profile img {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 100%;
}

.camps-tabs-wrapp input::placeholder {
    font-weight: bold;
}

.camps-tabs-wrapp input::-webkit-placeholder {
    font-weight: bold;
}

.camps-tabs-wrapp input::-moz-placeholder {
    font-weight: bold;
}

.table_company_warpper .dropdown-menu {
    right: 0;
}



@media(min-width: 767px) {
    .cutom-width-filter .col-2:nth-child(2) {
        width: 15%;
    }

    .cutom-width-filter .col-2:nth-last-child(1) {
        width: 18%;
    }

    .camps-tabs .mr {margin-right: 12%;}

    .table-responsive,
.table-responsive table {
    overflow: inherit !important;
}
}


/* ==========================28 February, 2022=============================== */
.plan-block {
    display: flex;
    overflow-y: hidden;
    overflow-x: auto;
    width: 100%;
    margin-left: 10px;
}

.plan-block > div {
    min-width: 32%;
    margin-right: 10px;

}

.plan-block > div:nth-child(1) {
    background-color: #fff1c9 !important;
}

.plan-block > div:nth-child(2) {
    background-color: #f2f7ff !important;
}

.plan-block > div:nth-child(3) {
    background-color: #f7f2f2 !important;
}

.plan-block > div:nth-child(4) {
    background-color: #F5FFC9 !important;
}

.plan-block > div:nth-child(5) {
    background-color: #EDF1D6 !important;
}

.plan-block > div:nth-child(6) {
    background-color: #FEFBE9 !important;
}

.plan-block > div:nth-child(1) ul li:nth-child(1),
.plan-block > div:nth-child(7) ul li:nth-child(1){
    background-color: #FFC108 !important;
}

.plan-block > div:nth-child(1) ul li .themebtn,
.plan-block > div:nth-child(7) ul li .themebtn {
    background: #FFC108 !important;
    border-color: #FFC108 !important;
    color: #fff;
}

.plan-block > div:nth-child(2) ul li:nth-child(1),
.plan-block > div:nth-child(8) ul li:nth-child(1){
    background-color: #467DD6 !important;
}

.plan-block > div:nth-child(2) ul li .themebtn,
.plan-block > div:nth-child(8) ul li .themebtn {
    background: #467DD6 !important;
    border-color: #467DD6 !important;
    color: #fff;
}

.plan-block > div:nth-child(3) ul li:nth-child(1),
.plan-block > div:nth-child(9) ul li:nth-child(1){
    background-color: #FD6A69 !important;
}

.plan-block > div:nth-child(3) ul li .themebtn,
.plan-block > div:nth-child(9) ul li .themebtn {
    background: #FD6A69 !important;
    border-color: #FD6A69 !important;
    color: #fff;
}

.plan-block > div:nth-child(4) ul li:nth-child(1),
.plan-block > div:nth-child(10) ul li:nth-child(1){
    background-color: #804674 !important;
}

.plan-block > div:nth-child(4) ul li .themebtn,
.plan-block > div:nth-child(10) ul li .themebtn {
    background: #804674 !important;
    border-color: #804674 !important;
    color: #fff;
}

.plan-block > div:nth-child(5) ul li:nth-child(1),
.plan-block > div:nth-child(11) ul li:nth-child(1){
    background-color: #609966 !important;
}

.plan-block > div:nth-child(5) ul li .themebtn,
.plan-block > div:nth-child(11) ul li .themebtn {
    background: #609966 !important;
    border-color: #609966 !important;
    color: #fff;
}

.plan-block > div:nth-child(6) ul li:nth-child(1),
.plan-block > div:nth-child(12) ul li:nth-child(1){
    background-color: #BE6DB7 !important;
}

.plan-block > div:nth-child(6) ul li .themebtn,
.plan-block > div:nth-child(12) ul li .themebtn {
    background: #BE6DB7 !important;
    border-color: #BE6DB7 !important;
    color: #fff;
}

/* width */
.plan-block::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  .plan-block::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .plan-block::-webkit-scrollbar-thumb {
    background: #FFC108;
  }
  
  /* Handle on hover */
  .plan-block::-webkit-scrollbar-thumb:hover {
    background: #FFC108;
  }

@media(max-width: 1680px) {
	.Team-Card div:nth-child(1) {width: 40%;}
	.Team-Card div:nth-child(2) {width: 55%; margin-left: 0;}
	.team-card-main {justify-content: space-between;}
}

@media(max-width: 1400px) {
	html {font-size: 14px;}
}

@media(max-width: 991px) {
	.aboutus-content1 .content,
	.aboutus-content2,
	.aboutus-content3,
	.aboutus-card,
	.team-card-main,
	.Team-subpart,
    .cutom-width-filter,
    .card_body.pt-4 > .row + .d-flex.col-12.align-items-center.justify-content-between.pt-3.border-top,
    .card_body.pt-4 > .row + .d-flex.col-12.align-items-center.justify-content-between.pt-3.border-top > .col-md-7.col-7.d-flex.align-items-center.justify-content-between{
		flex-wrap: wrap;
	}

	.aboutus-content1 .content .aboutus-img1,
	.aboutus-content1 .content .aboutus-contentin,
	.aboutus-content2 .aboutus-img2,
	.aboutus-content2 .aboutus-contentin2,
	.aboutus-content3 .aboutus-img3,
	.aboutus-content3 .capital,
	.home-container > .container > .row > .col-md-9,
	.home-container > .container > .row > .col-md-3,
	.home-container > div > .container > .row > .col-lg-9,
	.home-container > div > .container > .row > .col-lg-3,
    .cutom-width-filter .col-2,
    .cutom-width-filter .col-2:nth-child(2),
    .cutom-width-filter .col-2:nth-last-child(1),
    .card_body.pt-4 > .row + .d-flex.col-12.align-items-center.justify-content-between.pt-3.border-top > .col-md-7.col-7.d-flex.align-items-center.justify-content-between,
    .center-container > .row > .col-md-6:nth-child(2) .w-65 {
		width: 100%;
	}

	.aboutus-img2,
	.aboutus-img3 {
		text-align: center;
	}

	.Team-subpart-main,
	.Team-subpart,
	.aboutus-content3,
	.home-container > .container > .row > .col-md-9,
    .home-container > div > .container > .row > .col-lg-9,
    .home-container > .container > .row > .col-lg-9{
		margin-bottom: 30px;
	}

	.aboutus-content4 {
		margin-top: 30px;
	}

	nav.navbar.fixed-top.navbar-expand-lg {margin-left: 0 !important;}
	ul.navbar-nav.ms-5.mb-2.mb-lg-0.mt-md-0.mt-3.navbarmenu {width: 100%;}
	.navbarheight {flex-wrap: wrap;}
	.navbarheight form {width: 100% !important;}
	.Team-Card div:nth-child(1) {width: 40%;}
	.Team-Card div:nth-child(2) {width: 55%; margin-left: 0;}
	.team-card-main {justify-content: space-between;}
	.team-subpart-img {flex: none; width: 100%; margin-bottom: 20px;}
	.team-subpart-text {flex: none; width: 100%; text-align: center;}
	#news-slider {margin-top: 0; display: flex; flex-wrap: nowrap; overflow-y: hidden; overflow-x: auto;}
	.post-slide {margin: 0; min-width: 85%; margin-right: 20px;}
	div#section4 {margin-top: 30px;}
	.nav-components {margin-right: 30px;}
	.aboutus-img1 {margin: 30px 0;}
    .cs_clst {flex: none; max-width: 100%; width: 100%;}
    .form-control {margin-bottom: 10px;}
    .cutom-width-filter .col-2 {margin-bottom: 10px;}
    .card_body.pt-4 > .row + .d-flex.col-12.align-items-center.justify-content-between.pt-3.border-top a {margin: 0 !important; width: 100%; margin-top: 10px !important;}
    .card_body.pt-4 > .row + .d-flex.col-12.align-items-center.justify-content-between.pt-3.border-top > .col-md-7.col-7.d-flex.align-items-center.justify-content-between > .col-md-4 {height: 125px !important; width: 100%; margin-bottom: 20px;}
    .card_body.pt-4 .col-md-4.order-md-2.text-end {margin-bottom: 20px; text-align: center !important;}
    .center-container > .row > .col-md-6:nth-child(2) .w-65 {padding-right: 15px !important; display: flex; align-content: center; flex-wrap: wrap;}
    .center-container .bg-blue.vh-100.position-fixed {display: flex; align-items: center; justify-content: center; padding: 0 30px;}
    .center-container .abs-center-x-y {top: auto;left: auto; transform: none; position: relative !important; width: 89% !important;}
    .login-main-wrapp > .row > .col-md-6 {padding: 0 15px !important;}
    .login-main-wrapp {padding: 0 0 !important;}
    form.w-lg-lg.w-100.mt-lg-0.mt-3.ms-lg-2 .form-control {margin-bottom: 0;}
    .all-campus-wrapp .ac-campus-form .form-control {margin-bottom: 0;}
    .campus-plan-wrapp.candidate_plan .pln_box {flex: 0 0 100%; max-width: 100%; margin-top: 0 !important; margin-bottom: 30px;}
    .navbarheight section.ms-md-2.mt-md-0.mt-3 {text-align: right; width: 100%;}
    .plan-block > div {min-width: 50%;}
    header.d-flex.justify-content-between.mb-3.p-0 .col-3 {text-align: right;}
    form.d-flex.justify-content-between .input-group.mb-3.my-3 .form-control {margin-bottom: 0;}
    .col-md-12.col-12.m-0.p-0 {overflow-x: auto;}
    .card > hr + footer .d-flex.align-items-center.w-100 {flex-wrap: wrap; justify-content: center;}
    .card > hr + footer .d-flex.align-items-center.w-100  > div {width: 100%; justify-content: center;}
    .search_header.py-4.job_card_header.form_height.d-flex.justify-content-between {flex-wrap: wrap;}
    .job_card_header .cs_search_F {flex: 1 0 100%; max-width: 100%;}
    .filter_btn_wrap, .filter_btn_wrap .filter-btn button {width: 100%; margin-bottom: 10px;}
    .card > hr + footer .d-flex.align-items-center.w-100 a {margin-bottom: 10px;}
}

@media(max-width: 767px) {
	div#counter_section > .cs_row > .col-md-3 {margin-bottom: 10px;}

	.aboutus-content1 .content,
	.aboutus-content2,
	.aboutus-content3,
	.aboutus-card,
	.team-card-main,
	.Team-subpart,
	.d-flex.col-12.pb-3.text-white,
	.cs_tabs.d-flex.scroll_navbar .cs_tabs .cs_tabs_bd,
	.cs_tabs.d-flex.scroll_navbar .cs_tabs .cs_tabs_bd,
    .cs_tabs .cs_tabs_bd,
    .plan-main-wrapp,
    .card_body.pt-4 > .py-2.d-flex.align-items-center.justify-content-between {
		flex-wrap: wrap;
	}

	.aboutus-content1 .content .aboutus-img1,
	.aboutus-content1 .content .aboutus-contentin,
	.aboutus-content2 .aboutus-img2,
	.aboutus-content2 .aboutus-contentin2,
	.aboutus-content3 .aboutus-img3,
	.aboutus-content3 .capital,
	.container.shadow.p-2.br-5.col-3.col-md-2\.5.bg-purple,
	.pagination li:nth-child(1),
	.pagination li:nth-last-child(1),
    .plan-main-wrapp .pln_box1,
    .card_body.pt-4 > .py-2.d-flex.align-items-center.justify-content-between > div:nth-child(2) {
		width: 100%;
	}

    .cs_tabs2 {
        flex-wrap: nowrap; 
        overflow-y: hidden; 
        overflow-x: auto;
        white-space: nowrap;
        white-space: nowrap;
    }

    .home-container .navbar1::-webkit-scrollbar,
    .cs_tabs2::-webkit-scrollbar {
        display: none;
    }

    .quiz-card-wrapp .pt-5.text-end .btn_sm,
    .quiz-card-wrapp .pt-5.text-end a {
        width: 100%; margin-bottom: 10px;
    }

    .themebtn, 
    .themebtn:focus, 
    .themebtn:active, 
    .themebtn2, 
    .themebtn2:focus, 
    .themebtn2:active {
        padding: 6px 2rem;
    }

    table .themebtn, 
    table .themebtn:focus, 
    table .themebtn:active, 
    table .themebtn2, 
    table .themebtn2:focus, 
    table .themebtn2:active {
        padding: 9px 1rem;
    }


	.home-container .navbar1 {margin: 0; padding: 1%; overflow-x: auto; overflow-y: hidden; flex-wrap: nowrap; white-space: nowrap;}
	.nav-components {margin-right: 20px;}
	.aboutus-img3 {margin-bottom: 10px;}
	.aboutus-content3 {margin: 0;}
	.Team-Card div:nth-child(1) {width: 40%;}
	.Team-Card div:nth-child(2) {width: 55%; margin-left: 0;}
	.team-card-main {justify-content: space-between;}
	.team-subpart-img {flex: none; width: 100%; margin-bottom: 20px;}
	.team-subpart-text {flex: none; width: 100%; text-align: center;}
	#news-slider {margin-top: 0; display: flex; flex-wrap: nowrap; overflow-y: hidden; overflow-x: auto;}
	.post-slide {margin: 0; min-width: 85%; margin-right: 20px;}
	.post-slide .post-content {margin-top: 20px;}
	.list_flex > li {justify-content: space-between;}
	.list_flex > li > p:first-child {flex: none;}
	.card_header.pt-2.d-flex.align-items-start + .card_body.pt-2 > .pt-2 p {display: flex; justify-content: space-between;}
	.pagination {flex-wrap: wrap; text-align: center;}
    .container.c-title-block {padding: 0;}
    .container.c-title-block p {margin-bottom: 0 !important;}
    .ps-0 {padding-left: 15px !important;}
    .quiz-card-wrapp .pt-5.text-end {text-align: center !important;}
    .container.rounded-4.pt-3.pb-7.bg-whit {padding: 0;}
    .cs_card {margin-top: 10px;}
    .all-campus-wrapp .ac-campus-form {margin-top: 10px;}
    .card.border-0.rounded-lg {margin-top: 10px;}
    .cards.position-relative.bg-blue.br-5.text-white.p-2.mr-2.h-90 {margin-bottom: 10px;}
    .plan-block > div {min-width: 85%;}
    .pln_box1 {flex: 0 0 100%; max-width: 100%; margin-top: 0; margin-bottom: 30px;}
    .plan-block {margin-left: 0;}
    ul.dropdown-menu {max-height: 200px;}
    .card_body.pt-4 > .py-2.d-flex.align-items-center.justify-content-between > div:nth-child(2) a {width: 100%; display: block; margin-left: 0 !important; margin-bottom: 10px;}
    .form-select {max-height: 32px;}
    .cs_select_style:after {top: 6px;}
    .account-table-wrapp {width: 100%; overflow-x: auto;}
    .account-table-wrapp > .row {width: 100%;}
    .account-table-wrapp > .row .col-md-4 {width: 88%;}
    .account-table-content-wrapp > .row {flex-wrap: nowrap;}
    .at-header-wrapp > .row {flex-wrap: nowrap;}
    .account-header-block button {width: 100%; margin-bottom: 10px;}
}

.cs_table_style tr th,
.cs_table_style tr td{
    min-width: 100px;
    }

.cs_table_style tr th:nth-child(1),
.cs_table_style tr td:nth-child(1){
min-width: 20px;
}

.cs_table_style tr td:nth-last-of-type(1) .col.p-3 {
    padding: 0 !important;
}

.q_qtype {
    border-bottom: 1px solid #ddd;
}

.q_qtype:nth-last-child(1) {
    border-bottom: 0;
}

.cs_qq.q_done::after{
    display: none;

}

.cs_qq.q_done{
 
    color:#fff !important;

}

.timeparsent {
	display: flex;
    align-items: center;
}

.timeparsent span:nth-child(1) span {
	height: 50px;
    width: 50px;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid var(--danger);
    border-radius: 100%;
    font-size: 12px;
    margin-left: 16px;
}

.list-withouticon p span {
    margin-right: 41px;
}

.som {
    font-size: 12px !important;
}

.job-applied-header-wrapp > div {
    width: 19%;
}

.ctable table td {
    border-bottom: 1px solid #dddddd;
    vertical-align: top;
}

.ep-table tr th:nth-of-type(1),
.ep-table tr td:nth-of-type(1) {
    min-width: 350px;
}

.ep-table tr th:nth-of-type(2),
.ep-table tr td:nth-of-type(2) {
    min-width: 150px;
}

.ep-table tr th:nth-of-type(3),
.ep-table tr td:nth-of-type(3) {
    min-width: 120px;
}

.ep-table span {
    display: block;
}

.cja-image {
    margin-bottom: 30px;
}

.cal-icon-block {
    position: absolute;
    top: 26px;
    right: 7px;
    width: 16px;
    z-index: 1;
}

.react-datepicker-wrapper {
    position: relative;
    z-index: 2;
}

.react-datepicker-wrapper input {
    background-color: transparent !important;
}





@media(min-width: 767px) {
    .table-responsive, 
    .table-responsive table {
        overflow-x: auto !important;
    }
}

@media(max-width: 1250) {
    body {font-size: 11px;}
}

@media(max-width: 991px) {
    .job-applied-header-wrapp {flex-wrap: wrap;}
    .job-applied-header-wrapp > div {width: 48%;}
    .cja-image img {width: 150px;}
    .cja-cnt-block a.btn.btn-sm.ps-4.pe-4.me-2.btn-border.ms-3 {margin-left: 5px !important;}
    .navbar .dropdown-menu {right: 0;}
    .mb-xsm-10 {margin-bottom: 10px !important;}
    select.form-select.cs_flex_item.mb-xsm-10 {margin-bottom: 10px !important;}
}

@media(max-width: 767px) {
    .job-applied-header-wrapp > div {width: 100%;}

    .ep-table tr th:nth-of-type(1), 
    .ep-table tr td:nth-of-type(1) {
        min-width: 196px;
    }

    .cs_table_style th,
    .cs_table_style td {
        min-width: 100px;
    }

    .mx-xsm-0 {margin-left: 0 !important; margin-right: 0 !important;}
    .mt-xsm-0 {margin-top: 0 !important;}
    .mr-xsm-0 {margin-right: 0 !important;}
    .cja-image img {width: 150px;}
    .cs_clst2.cs-clst2-xsm {flex: 1 0 32%; max-width: 32%;}
    p.f-0-7.text-end.mb-0.mt-auto.w-100 {text-align: left !important;}
    .wp-check-label {width: 50%;}
    .wp-check-slider {width: 50%; display: flex !important; justify-content: end;}
    .wp-check-slider-wrapp {width: 100%;}
    .text-align-right-sm {text-align: right;}
    .video-input {flex-wrap: wrap;}
    .video-input input {width: 100%;}
    .w-for-mb {width: 70%;}
}



.cstm-dropdown:after {
    content: "";
    position: absolute;
    right: 8px;
    top: 17px;
    width: 5px;
    height: 5px;
    border: 1px solid;
    border-width: 1px 1px 0 0;
    transform: rotate(135deg);
    pointer-events: none;
}

.cstm-dropdown-item {
    max-height: 200px;
    overflow-x: hidden;
    overflow-y: auto;
}

input {
    outline: 0 !important;
}

.cstm-dropdown input {
    width: 100% !important;
}

.badge-text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0 !important;
    height: 30px;
}

.no-overlap {
    position: relative;
    z-index: 1;
}

.p-personal-badge-wrap > div {
    margin-bottom: 10px;
}

.p-personal-badge-wrap > div > div {
    height: 100%;
    min-height: 70px;
}

footer {
    position: relative;
    z-index: 1;
}

.share-wrap > div {
    display: flex !important;
    align-items: center;
}

.share-wrap > div > div {
    display: inline-block !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.share-wrap > div span.st-label {
    display: none !important;
}

.share-wrap > div .st-btn {
    min-width: auto !important;
}







